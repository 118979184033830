import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as globals from '../globals';
import jwt_decode from 'jwt-decode';
import {DateTime} from 'luxon';
import {Session} from '../entities/session';
import {Router} from '@angular/router';
import {AdminService} from './admin.service';
import {Subject} from 'rxjs/Subject';

@Injectable()
export class AuthService {
  public session: Session;
  public isAuthenticated: boolean;
  public loginSubject = new Subject<boolean>();

  constructor(private adminService: AdminService, private http: HttpClient, private router: Router) {}

  initSession() {
    // Check whether the token is expired and return true or false
    const now = DateTime.local().valueOf();
    const jwt = localStorage.getItem('jwt');
    const expiration = parseInt(localStorage.getItem('jwt_exp'), 10) || 0;

    this.isAuthenticated = now < expiration;

    if (jwt) {
      const tokenPayload: any = jwt_decode(jwt);
      this.session = new Session();
      this.session.id = tokenPayload.user.id;
      this.session.name = tokenPayload.user.name;
      this.session.role = tokenPayload.user.role;

      if (!this.session.id || !this.session.name || !this.session.role) {
        this.session = null;
        this.isAuthenticated = false;
      }
    } else {
      this.session = null;
      this.isAuthenticated = false;
    }
  }

  login(email: string, password: string) {
    // const nonce = Math.random().toString(36).substr(2, 5);

    return this.http
      .post<any>(globals.apiUrl + 'login', {
        login: {
          email: email,
          password: password
        }
      })
      .subscribe(response => {
        if (response.status === 'success') {
          const expiresAt = DateTime.fromMillis(response.jwt_exp * 1000);

          localStorage.setItem('jwt', response.jwt);
          localStorage.setItem('jwt_exp', JSON.stringify(expiresAt.valueOf()));

          this.initSession();
          this.loginSubject.next(true);

          this.router.navigate(this.adminService.getRoute('admin'));
        } else {
          this.loginSubject.next(false);
        }
      });
  }

  logout() {
    localStorage.removeItem('jwt');
    localStorage.removeItem('jwt_exp');
    this.initSession();
    this.http.get<any>(globals.apiUrl + 'logout');
  }
}
