import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Md5} from 'ts-md5/dist/md5';
import {ProductionService} from '../../services/production.service';
import {ReservationService} from '../../services/reservation.service';
import * as globals from '../../globals';
import {AdminService} from '../../services/admin.service';
import {Subscription} from 'rxjs/Subscription';

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.scss']
})
export class ReservationComponent implements OnInit, OnDestroy {
  public apiUrl: string;
  routeParamsSubscription: Subscription;

  constructor(
    private productionService: ProductionService,
    public reservationService: ReservationService,
    private adminService: AdminService,
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.apiUrl = globals.apiUrl;
  }

  ngOnInit() {
    this.routeParamsSubscription = this.route.params.subscribe(
      params => {
        this.reservationService.isAdmin = this.route.snapshot.url.length > 0 ? this.route.snapshot.url[0].path === 'admin' : false;
        this.adminService.initRoute(params.productionId);

        if (this.route.snapshot.url[0].path === 'admin') {
          this.getProduction(Md5.hashStr(params.productionId).toString());
        } else {
          this.getProduction(params.productionId);
        }
      },
      error => {
        window.location.reload();
      }
    );
  }

  getProduction(productionGUID: string): void {
    const reservation = this.reservationService.reservation;
    const productionSubscription = this.productionService.getProduction(productionGUID).subscribe(
      production => {
        // reservation.production = production;
        this.changeDetectorRef.markForCheck();
        productionSubscription.unsubscribe();
      },
      error => {
        window.location.reload();
      }
    );
  }

  ngOnDestroy() {
    if (this.routeParamsSubscription) {
      this.routeParamsSubscription.unsubscribe();
    }
  }
}
