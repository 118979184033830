import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import * as globals from '../globals';
import {Performance} from '../entities/performance';
import {Production} from '../entities/production';

@Injectable()
export class PerformanceService {

  constructor(private http: HttpClient) {
  }

  getPerformances(adminToken: string, production: Production): Observable<Performance[]> {
    return this.http.get<Performance[]>(globals.apiUrl + 'performances/' + production.id + '/' + adminToken);
  }

}
