import {Injectable} from '@angular/core';
import {Router, CanActivate} from '@angular/router';
import {AuthService} from './auth.service';
import {ReservationService} from './reservation.service';
import {AdminService} from './admin.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private authService: AuthService,
              private adminService: AdminService,
              private reservationService: ReservationService,
              private router: Router) {
  }

  canActivate(): boolean {
    if (!this.authService.isAuthenticated) {
      this.router.navigate(this.adminService.getRoute('admin', 'login'));
      return false;
    }
    return true;
  }
}
