import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {ActivatedRoute, Route} from '@angular/router';
import {AdminService} from '../../../services/admin.service';
import {Observable} from 'rxjs/Observable';
import {Subscription} from 'rxjs/Subscription';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  public login: string;
  public password: string;
  public loginError: boolean;
  loginSubscription: Subscription;

  constructor(public authService: AuthService,
              private route: ActivatedRoute,
              private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    setTimeout(() => {
      if (this.route.snapshot.data['action'] === 'logout') {
        this.authService.logout();
        this.changeDetectorRef.markForCheck();
      }
    });
    this.loginSubscription = this.authService.loginSubject.subscribe(success => {
      this.loginError = !success;
      this.changeDetectorRef.markForCheck();
    });
  }

  ngOnDestroy() {
    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
    }
  }
}
