<div>
  <div class="modal-header">
    <h3 class="modal-title">Es wurden bereits Pl&auml;tze ausgew&auml;hlt</h3>
  </div>
  <div class="modal-body">
    <p *ngIf="performance">
      Sie haben f&uuml;r den
      <b>{{performance.date | dateTimeFromFormat:'yyyy-MM-dd HH:mm:ss' | date:'d. MMMM yyyy | HH:mm \'Uhr\''}}</b>
      bereits Pl&auml;tze ausgew&auml;hlt.
    </p>
    <p>
      Wenn Sie jetzt ein anderes Datum aussuchen, werden die bereits ausgew&auml;hlten Pl&auml;tze wieder freigegeben.
    </p>
    <p>
      Falls Sie f&uuml;r verschiedene Daten Pl&auml;tze reservieren m&ouml;chten,
      t&auml;tigen Sie bitte zwei separate Reservationen.
    </p>
  </div>
  <div class="modal-footer">
    <button class="btn btn-warning" (click)="changePerformance()">Datum wechseln</button>
    <button class="btn btn-default" (click)="cancel()">Nicht wechseln</button>
  </div>
</div>
