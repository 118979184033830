<div id="progressIndicatorAnchor">
  <span
    *ngFor="let number of numbers"
    class="badge rounded-pill"
    [class.bg-white]="number > value"
    [class.bg-secondary]="number === value"
    [class.bg-primary]="number < value"
    >&nbsp;</span
  >
</div>
