import {Address} from './address';
import {Performance} from './performance';
import {Production} from './production';
import {RangeSet} from './rangeSet';
import {SeatRange} from './seatRange';

export class Reservation {
  production: Production;
  performance: Performance;

  address: Address;
  selectedSeats: number[];
  allSelectedSeats: number[]; // original seats when editing reservations
  categories: any[];
  seatRanges: SeatRange[];
  categoriesByRangeSet: RangeSet[];
  selectedCategories: number;
  total: number;

  editingToken: any;
}
