import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {of} from 'rxjs';
import {tap} from 'rxjs/operators';
import * as _ from 'underscore';
import * as globals from '../globals';
import {ReservationService} from './reservation.service';
import {Performance} from '../entities/performance';
import {RangeSet} from '../entities/rangeSet';
import {SeatRange} from '../entities/seatRange';

@Injectable()
export class CategoryService {

  constructor(private http: HttpClient,
              private reservationService: ReservationService) {
  }

  loadCategories(performance: Performance) {
    if (!performance) {
      return of(undefined);
    }

    // Load all categories.
    return this.http.get<any>(globals.apiUrl + 'categories/' + this.reservationService.reservation.production.id +
      '/' + performance.id)
      .pipe(tap((data) => {
        const categories = [];
        const seatRanges: SeatRange[] = [];
        const categoriesByRangeSet: RangeSet[] = [];

        // Parse categories
        for (let categoryIndex = 0; categoryIndex < data.length; categoryIndex++) {
          const category = data[categoryIndex];

          // Parse ID.
          category.id = parseInt(category.id, 10);
          // Convert price.
          category.price = parseFloat(category.price);
          // Initialize value (i.e. number of seats in this category).
          category.value = 0;

          if (!category.seatRanges) {
            // Create dummy range if no range was configured explicitely.
            category.seatRanges = '1-1000000';
          }

          // Parse seat range(s).
          const rawSeatRanges = category.seatRanges.split(',');
          category.seatRanges = [];

          rawSeatRanges.forEach((rawSeatRange) => {
            const seatRangeRaw = rawSeatRange.split('-', 2);
            const fromSeatId = parseInt(seatRangeRaw[0], 10);
            const toSeatId = parseInt(seatRangeRaw[1], 10);

            let seatRange = _.find(seatRanges, {fromSeatId: fromSeatId, toSeatId: toSeatId});

            if (!seatRange) {
              seatRange = new SeatRange();
              seatRange.fromSeatId = fromSeatId;
              seatRange.toSeatId = toSeatId;
              seatRange.guid = Math.floor(Math.random() * 10000);
              seatRanges.push(seatRange);
            }

            category.seatRanges.push(seatRange);
          });

          categories.push(category);
        }

        // Collect seat ranges into range sets.
        categories.forEach((category) => {
          category.seatRanges.forEach((seatRange) => {
            let rangeSet = _.find(categoriesByRangeSet, function (set) {
              return (set.categories.indexOf(category) !== -1 || set.ranges.indexOf(seatRange) !== -1);
            });

            if (!rangeSet) {
              rangeSet = new RangeSet();
              rangeSet.ranges = [];
              rangeSet.categories = [];
              rangeSet.label = category.label;
              rangeSet.selectedSeatsCount = 0;
              categoriesByRangeSet.push(rangeSet);
            }

            if (rangeSet.ranges.indexOf(seatRange) === -1) {
              rangeSet.ranges.push(seatRange);
            }

            if (rangeSet.categories.indexOf(category) === -1) {
              rangeSet.categories.push(category);
              rangeSet.label = this.findCommonPrefix(rangeSet.categories);
              this.removeCommonPrefix(rangeSet.categories, rangeSet.label);
            }
          });
        });

        // Preselect categories based on selected seats.
        this.reservationService.reservation.selectedSeats.forEach((selectedSeatId) => {
          categoriesByRangeSet.forEach((rangeSet) => {
            // Find appropriate category.
            const seatRange = _.find(rangeSet.ranges, function (range) {
              return (range.fromSeatId <= selectedSeatId && range.toSeatId >= selectedSeatId);
            });

            if (seatRange) {
              const defaultCategory = _.find(rangeSet.categories, {default: true}) || rangeSet.categories[0];
              defaultCategory.value += 1;
              rangeSet.selectedSeatsCount += 1;
            }
          });
        });

        this.reservationService.reservation.categories = categories;
        this.reservationService.reservation.seatRanges = seatRanges;
        this.reservationService.reservation.categoriesByRangeSet = categoriesByRangeSet;
        this.reservationService.reservation.selectedCategories = this.reservationService.reservation.selectedSeats.length;

        this.evaluateSelectedCategories();
        this.reservationService.saveReservation();
      }));
  }

  findCommonPrefix(categories: any[]) {
    const A = categories
        .concat()
        .sort(function compare(a, b) {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        }),
      a1 = A[0].label, a2 = A[A.length - 1].label, L = a1.length;
    let i = 0;
    while (i < L && a1.charAt(i) === a2.charAt(i)) {
      i++;
    }
    return a1.substring(0, i);
  }

  removeCommonPrefix(categories, prefix) {
    categories.forEach((category) => {
      const shortLabel = category.label.replace(new RegExp('^' + prefix + '\s*'), '');
      category.shortLabel = (shortLabel !== '' ? shortLabel : category.label);
    });
  }

  evaluateSelectedCategories() {
    let total = 0;
    let selectedCategories = 0;

    if (this.reservationService.reservation.categories) {
      this.reservationService.reservation.categories.forEach((category) => {
        selectedCategories += category.value;
        total += category.value * category.price;
      });
    }

    this.reservationService.reservation.selectedCategories = selectedCategories;
    this.reservationService.reservation.total = total;
  }
}
