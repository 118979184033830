<div *ngIf="reservationService.reservation && reservationService.reservation.production">
  <header class="mb-4">
    <div class="header-bar-container mb-2">
      <div class="header-bar"></div>
    </div>
    <h3 id="pageTitle">{{reservationService.reservation.production.organizer}}</h3>
    <h2>{{reservationService.reservation.production.title}}</h2>
  </header>
  <router-outlet></router-outlet>
</div>
