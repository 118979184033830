<div>
  <h3>Reservation suchen</h3>

  <form id="search_form" name="search_form" novalidate>
    <fieldset>
      <div class="form-group">
        <label for="query">Suchtext</label>
        <div class="input-group mb-2">
          <input
            type="text"
            id="query"
            name="query"
            #query="ngModel"
            class="form-control"
            placeholder="Suchtext"
            [(ngModel)]="adminService.admin.query"
            minlength="3"
            required />
          <small class="error" *ngIf="query.errors?.minlength"> * muss mindestens 3 Zeichen lang sein </small>
        </div>
      </div>
      <div class="form-group">
        <label for="reservationDate">Reservationsdatum</label>
        <div class="input-group mb-2">
          <input
            class="form-control"
            placeholder="dd.mm.yyyy"
            id="reservationDate"
            name="reservationDate"
            [(ngModel)]="adminService.admin.reservationDate"
            bsDatepicker
            [bsConfig]="{isAnimated: true, dateInputFormat: 'DD.MM.YYYY'}"
            #reservationDatePicker="bsDatepicker" />
          <div class="input-group-append">
            <button type="button" class="btn btn-outline-secondary" (click)="reservationDatePicker.toggle()">
              <i class="fa fa-calendar"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="performanceDate">Auff&uuml;hrungsdatum</label>
        <div class="input-group mb-2">
          <input
            class="form-control"
            placeholder="dd.mm.yyyy"
            id="performanceDate"
            name="performanceDate"
            [(ngModel)]="adminService.admin.performanceDate"
            bsDatepicker
            [bsConfig]="{isAnimated: true, dateInputFormat: 'DD.MM.YYYY'}"
            #presentationDatePicker="bsDatepicker" />
          <div class="input-group-append">
            <button type="button" class="btn btn-outline-secondary" (click)="presentationDatePicker.toggle()">
              <i class="fa fa-calendar"></i>
            </button>
          </div>
        </div>
      </div>
    </fieldset>
  </form>

  <div class="btn-group">
    <button type="button" class="btn btn-default" (click)="adminService.export()">Herunterladen</button>
  </div>
  <div class="btn-group pull-right">
    <button
      type="submit"
      class="btn btn-primary"
      (click)="search()"
      [disabled]="query.invalid && adminService.admin.reservationDate === null && adminService.admin.performanceDate === null">
      Suchen
    </button>
  </div>
  <div class="clearfix mt-4"></div>
  <h3 *ngIf="searched">Suchresultat</h3>
  <table *ngIf="searched" style="width: 100%">
    <thead *ngIf="adminService.admin.searchResult && adminService.admin.searchResult.length > 0">
      <tr>
        <th>Datum der Reservation</th>
        <th>Adresse und Details</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let address of adminService.admin.searchResult">
        <tr style="border-top: 1px solid #e5e5e5">
          <td style="vertical-align: top">
            <div>
              {{ address.timestamp | dateTimeFromSql | date: "d. MMMM yyyy | HH:mm 'Uhr'" }}
            </div>
          </td>
          <td style="vertical-align: top">
            <div>{{ address.firstname }} {{ address.lastname }}, {{ address.city }}</div>
          </td>
          <td style="vertical-align: top; text-align: right">
            <div class="btn-group">
              <div class="btn-group-toggle" data-toggle="buttons">
                <label class="btn my-1" [ngClass]="{'btn-secondary': address.showDetails, 'btn-outline-secondary': !address.showDetails}">
                  <input type="checkbox" style="display: none" (click)="getReservationDetails(address)" [checked]="address.showDetails" />Details
                </label>
              </div>
            </div>
          </td>
        </tr>
        <tr *ngIf="address.showDetails">
          <td style="vertical-align: top">&nbsp;</td>
          <td style="vertical-align: top" class="pb-4">
            <div><b>Adresse:</b> {{ address.address }}, {{ address.zip }} {{ address.city }}</div>
            <div>
              <b>Auff&uuml;hrungsdatum:</b> {{ address.date | dateTimeFromSql | date: "d. MMMM yyyy | HH:mm 'Uhr'" }}<br />
              <b>Sitze:</b>
              <ul>
                <li *ngFor="let seat of address.seats">
                  {{ getSeatLabel(seat.s) }} <span style="opacity: 0.5">| {{ seat.label }}</span>
                </li>
              </ul>
              <b *ngIf="address.options && address.options.length > 0">Optionen:</b>
              <ul *ngIf="address.options && address.options.length > 0">
                <li *ngFor="let option of address.options">{{ option.amount }} &times; {{ option.label }}</li>
              </ul>
              <b>Bemerkungen:</b><br />
              <span>{{ address.notes }}</span>
            </div>
            <div>
              <b>Telefon:</b>&nbsp;<span style="white-space: nowrap">{{ address.phone }}</span>
              <br />
              <b>E-Mail:</b>&nbsp;<a href="mailto:{{ address.firstname }}%20{{ address.lastname }}%20%3C{{ address.email }}%3E">{{
                address.email
              }}</a>
            </div>
          </td>
          <td style="vertical-align: top; text-align: right">
            <button type="button" class="btn btn-warning mt-3 mb-1" (click)="editResevation(address)" *ngIf="address.showDetails">Bearbeiten</button>
            <button type="button" class="btn btn-danger mb-1" (click)="cancelReservation(address)" *ngIf="address.showDetails">Stornieren</button>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <p class="p-4 bg-warning rounded-box" *ngIf="searched && adminService.admin.searchResult?.length === 0">Keine Treffer gefunden.</p>
</div>
