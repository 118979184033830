import {Component, OnInit} from '@angular/core';
import {AdminService} from '../../services/admin.service';
import {ReservationService} from '../../services/reservation.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  public login: string;
  public password: string;

  constructor(public adminService: AdminService,
              public reservationService: ReservationService) {
  }

  ngOnInit() {
  }

}
