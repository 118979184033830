<div>
  <app-progress-indicator [value]="5" [max]="5"></app-progress-indicator>
  <h4>Vielen Dank</h4>

  <p class="p-4 bg-primary text-white rounded-box">
    <i class="fa fa-check"></i>
    Die Reservation wurde erfolgreich &uuml;bermittelt.
    Sie erhalten in wenigen Minuten eine Best&auml;tigung an die angegebene E-Mail-Adresse.
    Sie k&ouml;nnen diese Seite jetzt schlie&szlig;en.
  </p>

  <p class="p-4 bg-info rounded-box"
     *ngIf="reservationService.reservation.production.disclaimerHTML"
     [innerHtml]="reservationService.reservation.production.disclaimerHTML"></p>

  <nav class="row">
    <div class="col-sm nav-right">
      <button type="button"
              class="btn btn-default"
              (click)="reservationService.reset(true, seatsLinkRoute)">
        Neue Reservation
      </button>
      <a class="btn btn-primary"
         *ngIf="reservationService.reservation.production.website"
         [href]="reservationService.reservation.production.website">
        Zur&uuml;ck zur Hauptseite
      </a>
    </div>
  </nav>
</div>
