import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';
import {AdminService} from '../../../services/admin.service';
import {CategoryService} from '../../../services/category.service';
import {MessageService} from '../../../services/message.service';
import {PerformanceService} from '../../../services/performance.service';
import {ReservationService} from '../../../services/reservation.service';
import {Performance} from '../../../entities/performance';
import {Production} from '../../../entities/production';

@Component({
  selector: 'app-performance-selection',
  templateUrl: './performance-selection.component.html',
  styleUrls: ['./performance-selection.component.scss']
})
export class PerformanceSelectionComponent implements OnInit, OnDestroy {

  private static RESET_SELECTED_PRODUCTION = -1;
  private static UNKNOWN_PRODUCTION = 0;

  selectedPerformance: number; // performance ID;
  performances: Performance[];
  productionSubscription: Subscription;

  constructor(private performanceService: PerformanceService,
              public reservationService: ReservationService,
              private adminService: AdminService,
              private categoryService: CategoryService,
              private messageService: MessageService,
              private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    if (this.reservationService.reservation.production) {
      this.getPerformances(this.reservationService.reservation.production);
    }

    this.productionSubscription = this.reservationService.productionSource.subscribe(
      production => this.getPerformances(production)
    );
  }

  getPerformances(production: Production): void {
    const performancesSubscription = this.performanceService
      .getPerformances(this.adminService.adminToken, production)
      .subscribe((performances: Performance[]) => {
          performances.sort((a, b) => {
              return a.date.localeCompare(b.date);
            }
          );
          this.performances = performances;

          let performance = this.reservationService.reservation.performance ? this.performances.find((p) => {
            return p.id === this.reservationService.reservation.performance.id;
          }) : null;

          if (!performance && this.performances.length === 1) {
            // Automatically select the first (any only) performance
            performance = this.performances[0];
          }

          this.loadPerformanceDetails(performance, true);
          performancesSubscription.unsubscribe();
        },
        error => {
          this.performances = [];
          performancesSubscription.unsubscribe();
        });
  }

  selectPerformance(performanceId: number) {
    const performance = this.performances.find((p) => {
      return p.id === performanceId;
    });

    if (!this.reservationService.reservation.performance || !performance ||
      performance.id !== this.reservationService.reservation.performance.id) {
      if (this.reservationService.reservation.selectedSeats.length > 0) {
        const messageSubscription = this.messageService
          .alertChangePerformance(this.reservationService.reservation.performance)
          .subscribe((reason: string) => {
            if (reason === 'ok') {
              this.reservationService.unlockAllSeats();
              this.loadPerformanceDetails(performance, true);

              // Clear already selected places.
              this.reservationService.reservation.selectedSeats.length = 0;
            } else {
              this.loadPerformanceDetails(this.reservationService.reservation.performance, false);
            }

            this.reservationService.saveReservation();
            messageSubscription.unsubscribe();
          }, error => {
            messageSubscription.unsubscribe();
          });
      } else {
        this.loadPerformanceDetails(performance, true);
      }
    }
  }

  loadPerformanceDetails(performance: Performance, loadCategories: boolean) {
    // Change selected performance
    this.reservationService.selectPerformance(performance);
    this.reservationService.saveReservation();

    this.selectedPerformance = PerformanceSelectionComponent.RESET_SELECTED_PRODUCTION;
    setTimeout(() => {
      this.selectedPerformance = performance ? performance.id : PerformanceSelectionComponent.UNKNOWN_PRODUCTION;
      this.changeDetectorRef.markForCheck();
    }, 0);

    if (performance && loadCategories) {
      this.categoryService.loadCategories(performance);
    }
  }

  ngOnDestroy() {
    if (this.productionSubscription) {
      this.productionSubscription.unsubscribe();
    }
  }
}
