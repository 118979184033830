import { Component } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

@Component({
  selector: "app-cancel-reservation-modal",
  templateUrl: "./cancel-reservation-modal.component.html",
})
export class CancelReservationModalComponent {
  public modalService: BsModalService;

  constructor(private modalRef: BsModalRef) {}

  public ok() {
    this.modalService.setDismissReason("ok");
    this.modalRef.hide();
  }

  public cancel() {
    this.modalService.setDismissReason("cancel");
    this.modalRef.hide();
  }
}
