import {Component, OnDestroy, OnInit} from '@angular/core';
import {ReservationService} from '../../../services/reservation.service';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {AdminService} from '../../../services/admin.service';

@Component({
  selector: 'app-seat-selection',
  templateUrl: './seat-selection.component.html',
  styleUrls: ['./seat-selection.component.css']
})
export class SeatSelectionComponent implements OnInit, OnDestroy {

  baseRouteSubscription: Subscription;
  public homeLinkRoute: string[];
  public optionsLinkRoute: string[];

  constructor(public reservationService: ReservationService,
              private adminService: AdminService,
              private router: Router) {
  }

  ngOnInit() {
    this.baseRouteSubscription = this.adminService.routeBaseChanged.subscribe(routeBase => {
      this.homeLinkRoute = routeBase.concat();
      this.optionsLinkRoute = routeBase.concat('options');
    });

    this.homeLinkRoute = this.adminService.getRoute();
    this.optionsLinkRoute = this.adminService.getRoute('options');
  }

  navigate(route) {
    this.router.navigate(route);
  }

  ngOnDestroy() {
    if (this.baseRouteSubscription) {
      this.baseRouteSubscription.unsubscribe();
    }
  }
}
