import {Performance} from './performance';

export class Production {
  announce: boolean;
  description: string;
  disclaimer: string;
  disclaimerHTML: string;
  contact: boolean;
  id: string;
  organizer: string;
  title: string;
  website: string;
  performances: Performance[];
  seatingPlan: any;
}
