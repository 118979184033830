<div>
  <div class="modal-header">
    <h3 class="modal-title">Internet Explorer nicht unterst&uuml;tzt</h3>
  </div>
  <div class="modal-body">
    <p>Internet Explorer ist leider veraltet und muss aktualisiert werden, bevor Sie dieses Reservationssystem nutzen
      k&ouml;nnen.</p>
    <p>Wenn Sie Windows 10 verwenden, benutzen Sie bitte <strong>Edge</strong>, der standardm&auml;ssig enthalten ist.
    </p>
    <p>Andernfalls installieren Sie bitte einen modernen Browser wie
      <a href="https://www.mozilla.org/de/firefox/new/" target="_self">Firefox</a> oder
      <a href="https://www.google.com/chrome/">Chrome</a>.</p>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" (click)="back()">Zur&uuml;ck</button>
  </div>
</div>
