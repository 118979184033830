import {ChangeDetectionStrategy, Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {AdminService} from './services/admin.service';
import {ReservationService} from './services/reservation.service';
import {Admin} from './entities/admin';
import {AuthService} from './services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {MessageService} from './services/message.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Platzreservation';

  baseRouteSubscription: Subscription;
  public homeLinkRoute: string[];
  public contactLinkRoute: string[];
  public adminLinkRoute: string[];
  public logoutLinkRoute: string[];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private adminService: AdminService,
              private messageService: MessageService,
              public authService: AuthService,
              public reservationService: ReservationService) {
  }

  ngOnInit() {
    this.baseRouteSubscription = this.adminService.routeBaseChanged.subscribe(routeBase => {
      this.homeLinkRoute = routeBase.concat();
      this.contactLinkRoute = routeBase.concat('contact');
      this.adminLinkRoute = routeBase.concat('admin');
      this.logoutLinkRoute = routeBase.concat('logout');
    });
    this.homeLinkRoute = this.adminService.getRoute();
    this.contactLinkRoute = this.adminService.getRoute('contact');
    this.adminLinkRoute = this.adminService.getRoute('admin');
    this.logoutLinkRoute = this.adminService.getRoute('logout');

    this.adminService.admin = new Admin();
    this.authService.initSession();

    // Check for Internet Explorer 6-11
    // @ts-ignore
    const isIE11OrOlder = /*@cc_on!@*/false || !!document.documentMode;
    if (isIE11OrOlder) {
      this.messageService.alertOutdatedBrowser();
    }
  }

  navigate(route) {
    this.router.navigate(route);
  }

  ngOnDestroy() {
    if (this.baseRouteSubscription) {
      this.baseRouteSubscription.unsubscribe();
    }
  }
}
