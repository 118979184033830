import {Component, OnDestroy, OnInit} from '@angular/core';
import * as globals from '../../../globals';
import {AdminService} from '../../../services/admin.service';
import {ReservationService} from '../../../services/reservation.service';
import {Subscription} from 'rxjs/Subscription';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit, OnDestroy {

  baseRouteSubscription: Subscription;
  public seatsLinkRoute: string[];
  public apiUrl;

  constructor(public reservationService: ReservationService,
              private adminService: AdminService) {
    this.apiUrl = globals.apiUrl;
  }

  ngOnInit() {
    this.baseRouteSubscription = this.adminService.routeBaseChanged.subscribe(routeBase => {
      this.seatsLinkRoute = routeBase.concat('seats');
    });
    this.seatsLinkRoute = this.adminService.getRoute('seats');
  }

  ngOnDestroy() {
    if (this.baseRouteSubscription) {
      this.baseRouteSubscription.unsubscribe();
    }
  }
}
