import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LandingPageComponent} from './components/reservation/landing-page/landing-page.component';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {ReservationComponent} from './components/reservation/reservation.component';
import {SeatSelectionComponent} from './components/reservation/seat-selection/seat-selection.component';
import {OptionsComponent} from './components/reservation/options/options.component';
import {AddressComponent} from './components/reservation/address/address.component';
import {ContactComponent} from './components/contact/contact.component';
import {CheckComponent} from './components/reservation/check/check.component';
import {ConfirmationComponent} from './components/reservation/confirmation/confirmation.component';
import {AdminComponent} from './components/admin/admin.component';
import {LoginComponent} from './components/admin/login/login.component';
import {ReservationsComponent} from './components/admin/reservations/reservations.component';
import {AuthGuardService} from './services/auth-guard.service';
import {RoleGuardService} from './services/role-guard.service';

const childRoutes = [
  {
    path: '',
    component: LandingPageComponent
  },
  {
    path: 'seats',
    component: SeatSelectionComponent
  },
  {
    path: 'options',
    component: OptionsComponent
  },
  {
    path: 'address',
    component: AddressComponent
  },
  {
    path: 'check',
    component: CheckComponent
  },
  {
    path: 'confirmation',
    component: ConfirmationComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'admin',
    component: AdminComponent,
    children: [
      {
        path: '',
        component: ReservationsComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          expectedRole: 'admin'
        }
      },
      {
        path: 'login',
        component: LoginComponent
      }
    ]
  },
  {
    path: 'logout',
    component: LoginComponent,
    data: {
      action: 'logout'
    }
  }
];

const routes: Routes = [
  {
    path: ':productionId',
    component: ReservationComponent,
    children: childRoutes
  },
  {
    path: 'admin/:productionId',
    component: ReservationComponent,
    children: childRoutes
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
