<div>
  <div class="modal-header">
    <h3 class="modal-title">Auswahl nicht m&ouml;glich</h3>
  </div>
  <div class="modal-body">
    <p>Dieser Platz wurde in der Zwischenzeit von einem anderen Besucher ausgew&auml;hlt.</p>
  </div>
  <div class="modal-footer">
    <button class="btn btn-default" (click)="dismiss()">OK</button>
  </div>
</div>
