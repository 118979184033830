import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {AdminService} from '../../../services/admin.service';
import {AuthService} from '../../../services/auth.service';
import {ReservationService} from '../../../services/reservation.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit, OnDestroy {

  baseRouteSubscription: Subscription;
  addressFormSubscription: Subscription;
  public addressForm: FormGroup;
  public optionsLinkRoute: string[];
  public checkLinkRoute: string[];

  constructor(private router: Router,
              private formBuilder: FormBuilder,
              private adminService: AdminService,
              public reservationService: ReservationService,
              public authService: AuthService) {
  }

  ngOnInit() {
    this.baseRouteSubscription = this.adminService.routeBaseChanged.subscribe(routeBase => {
      this.optionsLinkRoute = routeBase.concat('options');
      this.checkLinkRoute = routeBase.concat('check');
    });
    this.optionsLinkRoute = this.adminService.getRoute('options');
    this.checkLinkRoute = this.adminService.getRoute('check');

    this.createForm();

    this.scrollToTop();
  }

  scrollToTop() {
    const pageTitleElement = document.getElementById('pageTitle');
    pageTitleElement.scrollIntoView();
  }

  createForm() {
    const isRequired = !this.authService.isAuthenticated;
    const address = this.reservationService.reservation.address;

    this.addressForm = this.formBuilder.group({
      firstname: new FormControl(address.firstname, isRequired ? [
        Validators.required,
        Validators.minLength(3)
      ] : []),
      lastname: new FormControl(address.lastname, isRequired ? [
        Validators.required,
        Validators.minLength(3)
      ] : []),
      address: new FormControl(address.address, isRequired ? [
        Validators.required,
        Validators.minLength(3)
      ] : []),
      zip: new FormControl(address.zip, isRequired ? [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(6)
      ] : []),
      city: new FormControl(address.city, isRequired ? [
        Validators.required,
        Validators.minLength(3)
      ] : []),
      email: new FormControl(address.email, isRequired ? [
        Validators.required,
        Validators.email,
        Validators.pattern('[a-zA-Z0-9._-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')
      ] : []),
      announceNextProduction: new FormControl(address.announceNextProduction, []),
      phone: new FormControl(address.phone, []),
      notes: new FormControl(address.notes, [
        Validators.maxLength(1000)
      ]),
    });

    this.addressFormSubscription = this.addressForm.valueChanges.subscribe(addr => {
      this.saveAddress();
    });
  }

  get firstname() {
    return this.addressForm.get('firstname');
  }

  get lastname() {
    return this.addressForm.get('lastname');
  }

  get address() {
    return this.addressForm.get('address');
  }

  get zip() {
    return this.addressForm.get('zip');
  }

  get city() {
    return this.addressForm.get('city');
  }

  get email() {
    return this.addressForm.get('email');
  }

  get announceNextProduction() {
    return this.addressForm.get('announceNextProduction');
  }

  get phone() {
    return this.addressForm.get('phone');
  }

  get notes() {
    return this.addressForm.get('notes');
  }

  back() {
    this.saveAddress();
    this.router.navigate(this.optionsLinkRoute);
  }

  continue() {
    this.saveAddress();
    this.router.navigate(this.checkLinkRoute);
  }

  saveAddress() {
    const address = this.reservationService.reservation.address;
    address.firstname = this.firstname.value;
    address.lastname = this.lastname.value;
    address.address = this.address.value;
    address.zip = this.zip.value;
    address.city = this.city.value;
    address.email = this.email.value;
    address.announceNextProduction = this.announceNextProduction.value;
    address.phone = this.phone.value;
    address.notes = this.notes.value;

    this.reservationService.saveReservation();
  }

  ngOnDestroy() {
    if (this.baseRouteSubscription) {
      this.baseRouteSubscription.unsubscribe();
    }

    if (this.addressFormSubscription) {
      this.addressFormSubscription.unsubscribe();
    }
  }
}
