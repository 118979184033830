<div>
  <div class="modal-header">
    <h3 class="modal-title">{{title}}</h3>
  </div>
  <div class="modal-body">
    <p>{{message}}</p>
  </div>
  <div class="modal-footer">
    <button class="btn btn-default" (click)="dismiss()">OK</button>
  </div>
</div>
