<h4>Ihre Mitteilung oder Anfrage</h4>

<p>
  Falls Sie sich auf eine Reservation beziehen, geben Sie bitte auch das Auff&uuml;hrungsdatum sowie die betroffenen Sitznummern an, damit wir die
  Reservation eindeutig zuordnen k&ouml;nnen.
</p>

<form name="contactForm" [formGroup]="contactForm" *ngIf="!sent">
  <fieldset>
    <div class="mb-2">
      <textarea id="message" name="message" class="form-control" autocomplete="off" autofocus formControlName="message" rows="6"></textarea>
    </div>

    <div class="row mb-2 p-0">
      <div class="col">
        <input
          type="text"
          id="firstname"
          name="firstname"
          class="form-control"
          placeholder="Vorname"
          autocomplete="given-name"
          formControlName="firstname" />
      </div>
      <div class="col">
        <input
          type="text"
          id="lastname"
          name="lastname"
          class="form-control"
          placeholder="Nachname"
          autocomplete="family-name"
          formControlName="lastname" />
      </div>
    </div>

    <div class="mb-2">
      <div class="input-group">
        <div class="input-group-text"><span class="fa fa-envelope"></span></div>
        <input type="text" id="email" name="email" class="form-control" placeholder="E-Mail-Adresse" autocomplete="email" formControlName="email" />
      </div>
    </div>

    <div class="mb-4">
      <div class="input-group">
        <div class="input-group-text"><span class="fa fa-phone"></span></div>
        <input
          type="text"
          id="phone"
          name="phone"
          class="form-control"
          placeholder="Telefonnummer"
          autocomplete="tel-national"
          formControlName="phone" />
      </div>
      <small class="error">
        <i>Optional</i>
      </small>
    </div>
  </fieldset>
</form>

<div class="p-2 mb-4 bg-primary text-white rounded-box" *ngIf="sent">
  <p>Vielen Dank, wir haben Ihre Anfrage erhalten.</p>
</div>

<div class="p-2 mb-4 bg-danger text-white rounded-box" *ngIf="error">
  <p>
    Ihre Anfrage konnte leider nicht &uuml;bermittelt werden. Bitte versuchen Sie es sp&auml;ter noch einmal oder wenden Sie sich direkt an den
    Veranstalter.
  </p>
</div>

<div class="p-2 mb-4 bg-warning rounded-box" *ngIf="contactForm.invalid">
  <p>Ihre Angaben sind noch nicht vollst&auml;ndig:</p>
  <ul>
    <li class="error" *ngIf="message.errors?.required || message.errors?.minlength">
      Bitte schreiben Sie uns Ihre <strong>Mitteilung oder Anfrage</strong>.
    </li>
    <li class="error" *ngIf="firstname.errors?.required || firstname.errors?.minlength || lastname.errors?.required || lastname.errors?.minlength">
      Bitte geben Sie Ihren <strong>Vor- und Nachnamen</strong> ein.
    </li>
    <li class="error" *ngIf="email.errors?.required">Bitte geben Sie Ihre <strong>E-Mail-Adresse</strong> an.</li>
    <li class="error" *ngIf="email.invalid && !email.errors?.required">
      Die eingegebene <strong>E-Mail-Adresse</strong> ist ung&uuml;ltig oder unvollst&auml;ndig.
    </li>
  </ul>
</div>

<nav class="row">
  <div class="col-sm nav-left">
    <button type="button" class="btn btn-default" *ngIf="!sent" (click)="back()" [disabled]="isSending">
      <i class="fa fa-caret-left"></i> Zur&uuml;ck
    </button>
  </div>
  <div class="col-sm nav-right">
    <button type="button" class="btn btn-primary" *ngIf="!sent" (click)="sendWithToken()" [disabled]="contactForm.invalid || isSending">
      Absenden
    </button>
    <button type="button" class="btn btn-default" *ngIf="sent" (click)="back()">OK</button>
  </div>
</nav>
