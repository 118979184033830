import {ChangeDetectorRef, Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {ReplaySubject, of} from 'rxjs';
import {tap} from 'rxjs/operators';
import * as globals from '../globals';
import {CategoryService} from './category.service';
import {PerformanceService} from './performance.service';
import {ReservationService} from './reservation.service';
import {Admin} from '../entities/admin';
import {Address} from '../entities/address';
import {Reservation} from '../entities/reservation';
import {MessageService} from './message.service';

@Injectable()
export class AdminService {

  public routeBaseChanged = new ReplaySubject<string[]>();

  public admin: Admin;
  public adminToken: string;
  private routeBase: string[] = ['/'];

  constructor(private http: HttpClient,
              private route: ActivatedRoute,
              private router: Router,
              private location: Location,
              private categoryService: CategoryService,
              private messageService: MessageService,
              private reservationService: ReservationService,
              private performanceService: PerformanceService) {
  }

  initRoute(productionId) {
    this.adminToken = productionId;
    this.routeBase = ['/'];

    if (this.reservationService.isAdmin) {
      this.routeBase.push('admin');
    }

    this.routeBase.push(productionId);
    this.routeBaseChanged.next(this.getRoute());
  }

  getRoute(...fragments) {
    return this.routeBase.concat(fragments);
  }

  search() {
    const data = {
      at: this.adminToken,
      q: this.admin.query,
      p: this.admin.production.id,
      rd: this.admin.reservationDate,
      pd: this.admin.performanceDate
    };

    return this.http.post<any>(globals.apiUrl + 'search', data)
      .pipe(
        tap(response => {
          if (response.r) {
            this.admin.searchResult = response.s;
          } else {
            this.admin.searchResult = [];
          }
        })
      );
  }

  getReservationDetails(address: Address) {
    if (address.showDetails && !address.details) {
      return this.http.get<any>(globals.apiUrl + 'search/details/' + address.id + '/' + this.adminToken)
        .pipe(
          tap(data => {
              if (data.r) {
                address.address = data.a.address;
                address.zip = data.a.zip;
                address.phone = data.a.phone;
                address.email = data.a.email;
                address.notes = data.a.notes;

                address.seats = data.s;
                address.options = data.o;
              }
            }
          ));
    }
  }

  export() {
    /*
    const data = {
      q: this.admin.query,
      rd: this.admin.reservationDate,
      pd: this.admin.performanceDate
    };
    */

    const getfileheaders = new HttpHeaders()
      .set('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
      .set('Authorization', 'Bearer ' + localStorage.getItem('jwt'));

    this.http
      .get(globals.apiUrl + 'export/' + this.reservationService.reservation.production.id, {
        responseType: 'blob',
        headers: getfileheaders
      })
      .subscribe((response) => {
        const url = window.URL.createObjectURL(response);
        const element = document.createElement('a');
        element.setAttribute('href', url);
        element.setAttribute('target', '_self');
        element.setAttribute('download', 'Platzreservation.xlsx');
        element.click();
      });
  }

  /**
   * Start editing the already booked seatingPlan with the given address.
   * @param reservation
   * @param address
   */
  editReservation(reservation: Reservation, address: Address /* , seatLabelFunction */) {
    this.http.get<any>(globals.apiUrl + 'edit/' + address.id + '/' + this.adminToken)
      .subscribe((data) => {
        this.performanceService.getPerformances(this.adminToken, reservation.production).subscribe(performances => {
          reservation.production.performances = performances;

          let categoryIndex;
          let dataIndex;
          let performanceIndex;
          const seatIds = [];

          reservation.editingToken = data.t;

          let loadCategoriesSubscription;

          // Change selected performance
          for (performanceIndex = 0; performanceIndex < reservation.production.performances.length; performanceIndex++) {
            if (reservation.production.performances[performanceIndex].id === parseInt(data.p, 10)) {
              reservation.performance = reservation.production.performances[performanceIndex];

              // Load categories.
              loadCategoriesSubscription = this.categoryService.loadCategories(reservation.performance);
              break;
            }
          }

          if (!loadCategoriesSubscription) {
            loadCategoriesSubscription = of();
          }

          loadCategoriesSubscription.subscribe(() => {
            const categories = reservation.categories.map(x => Object.assign({}, x)); // clone array

            // Reset categories.
            for (categoryIndex = 0; categoryIndex < categories.length; categoryIndex++) {
              categories[categoryIndex].value = 0;
            }

            // Set places.
            for (dataIndex = 0; dataIndex < data.d.length; dataIndex++) {
              for (categoryIndex = 0; categoryIndex < categories.length; categoryIndex++) {
                if (data.d[dataIndex].c === categories[categoryIndex].id) {
                  categories[categoryIndex].value += 1;
                  seatIds.push(data.d[dataIndex].s);
                }
              }
            }

            seatIds.sort((a, b) => a - b);

            reservation.selectedSeats = seatIds;
            reservation.allSelectedSeats = seatIds.concat();
            reservation.selectedCategories = data.d.length;
            reservation.categories = categories;

            // Set address.
            reservation.address = data.a;

            this.categoryService.evaluateSelectedCategories();
            this.reservationService.saveReservation();

            this.router.navigate(this.getRoute('seats'));
          });
        });
      });
  }

  /**
   * Cancel the seatingPlan with the given address.
   * @param address
   */
  cancelReservation(address, changeDetectorRef: ChangeDetectorRef) {
    this.messageService.alertCancelReservation().subscribe((reason: string) => {
      if (reason === 'ok') {
        this.http.get<any>(globals.apiUrl + 'cancel/' + address.id + '/' + this.adminToken)
          .subscribe((data) => {
            // Remove deleted address from model.
            this.removeAddress(address);
            changeDetectorRef.markForCheck();
          });
      }
    });
  }

  removeAddress(address) {
    if (this.admin.searchResult) {
      const searchResultIndex = this.admin.searchResult.indexOf(address);
      if (searchResultIndex !== -1) {
        this.admin.searchResult.splice(searchResultIndex, 1);
      }
    }

    if (this.admin.listResult) {
      const listResultIndex = this.admin.listResult.indexOf(address);
      if (listResultIndex !== -1) {
        this.admin.listResult.splice(listResultIndex, 1);
      }
    }
  }
}
