import {ChangeDetectorRef, Component, forwardRef, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {OnExecuteData, ReCaptchaV3Service} from 'ng-recaptcha';
import {Subscription} from 'rxjs/Subscription';
import {AdminService} from '../../services/admin.service';
import {AuthService} from '../../services/auth.service';
import {ContactService} from '../../services/contact.service';
import {ReservationService} from '../../services/reservation.service';
import {Contact} from '../../entities/contact';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, OnDestroy {

  private baseRouteSubscription: Subscription;
  private contactFormSubscription: Subscription;

  private allExecutionsSubscription: Subscription;
  private singleExecutionSubscription: Subscription;

  public contactForm: FormGroup;
  public homeLinkRoute: string[];
  public contact: Contact;

  public recentToken: string = '';
  public readonly executionLog: OnExecuteData[] = [];

  public isSending: boolean;
  public sent: boolean;
  public error: boolean;

  constructor(private router: Router,
              private renderer: Renderer2,
              private formBuilder: FormBuilder,
              private changeDetectorRef: ChangeDetectorRef,
              private adminService: AdminService,
              private recaptchaV3Service: ReCaptchaV3Service,
              public contactService: ContactService,
              public reservationService: ReservationService,
              public authService: AuthService) {
  }

  ngOnInit() {
    this.baseRouteSubscription = this.adminService.routeBaseChanged.subscribe(routeBase => {
      this.homeLinkRoute = routeBase.concat();
    });
    this.homeLinkRoute = this.adminService.getRoute();

    this.contact = new Contact();

    this.allExecutionsSubscription = this.recaptchaV3Service.onExecute
    .subscribe((data) => this.executionLog.push(data));

    this.createForm();
    this.focusMessageInput();
    this.resetStatus();
  }

  ngOnDestroy() {
    if (this.baseRouteSubscription) {
      this.baseRouteSubscription.unsubscribe();
    }

    if (this.contactFormSubscription) {
      this.contactFormSubscription.unsubscribe();
    }

    if (this.allExecutionsSubscription) {
      this.allExecutionsSubscription.unsubscribe();
    }

    if (this.singleExecutionSubscription) {
      this.singleExecutionSubscription.unsubscribe();
    }
  }

  get firstname() {
    return this.contactForm.get('firstname');
  }

  get lastname() {
    return this.contactForm.get('lastname');
  }

  get email() {
    return this.contactForm.get('email');
  }

  get phone() {
    return this.contactForm.get('phone');
  }

  get message() {
    return this.contactForm.get('message');
  }

  get captcha() {
    return this.contactForm.get('captcha');
  }

  public back() {
    this.router.navigate(this.homeLinkRoute);
  }

  public sendWithToken(): void {
    if (this.singleExecutionSubscription) {
      this.singleExecutionSubscription.unsubscribe();
    }

    this.singleExecutionSubscription = this.recaptchaV3Service.execute('send_contact_form')
      .subscribe((token) => {
        this.recentToken = token;
        this.send();
      });
  }

  private send() {
    if (!this.isSending) {
      this.isSending = true;
      this.sent = false;
      this.error = false;

      this.contact.firstname = this.firstname.value;
      this.contact.lastname = this.lastname.value;
      this.contact.email = this.email.value;
      this.contact.phone = this.phone.value;
      this.contact.message = this.message.value;
      this.contact.productionId = this.reservationService.reservation.production.id;

      this.contactService.contact(this.contact).subscribe(result => {
        this.isSending = false;

        if (result.r) {
          this.sent = true;
        } else {
          this.error = true;
        }

        this.changeDetectorRef.markForCheck();
      }, () => {
        this.isSending = false;
        this.error = true;

        this.changeDetectorRef.markForCheck();
      });
    }
  }

  private resetStatus() {
    this.isSending = false;
    this.sent = false;
    this.error = false;
  }

  private focusMessageInput() {
    setTimeout(() => {
      this.renderer.selectRootElement('#message').focus();
    });
  }

  private createForm() {
    const isRequired = !this.authService.isAuthenticated;
    const contact = this.contact;

    this.contactForm = this.formBuilder.group({
      firstname: new FormControl(contact.firstname, isRequired ? [
        Validators.required,
        Validators.minLength(3)
      ] : []),
      lastname: new FormControl(contact.lastname, isRequired ? [
        Validators.required,
        Validators.minLength(3)
      ] : []),
      email: new FormControl(contact.email, isRequired ? [
        Validators.required,
        Validators.email,
        Validators.pattern('[a-zA-Z0-9._-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')
      ] : []),
      phone: new FormControl(contact.phone, []),
      message: new FormControl(contact.message, [
        Validators.required,
        Validators.maxLength(1000)
      ])
    });
  }
}
