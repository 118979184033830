<app-progress-indicator [value]="4" [max]="5"></app-progress-indicator>
<div [class.wait-container]="reservationService.isBooking">
  <div [class.wait]="reservationService.isBooking">
    <h4><span class="step-number">5</span>Fast fertig</h4>
    <p class="p-4 bg-info rounded-box">
      Bitte kontrollieren Sie nochmals alle Angaben. Klicken Sie auf <b>Zur&uuml;ck</b>, um falsche Angaben zu korrigieren oder auf
      <b>Reservation absenden</b>, um die Reservation abzuschliessen. Sie erhalten anschliessend eine Best&auml;tigung an die angegebene
      E-Mail-Adresse.
    </p>

    <h5>Auff&uuml;hrung</h5>
    <p *ngIf="reservationService.reservation.performance">
      {{ reservationService.reservation.performance.date | dateTimeFromFormat: "yyyy-MM-dd HH:mm:ss" | date: "d. MMMM yyyy | HH:mm 'Uhr'" }}
    </p>

    <hr />
    <h5>Pl&auml;tze</h5>
    <div class="mb-4">
      <b>Sitznummern:</b>
      <span *ngFor="let selectedSeat of reservationService.reservation.selectedSeats" class="badge badge-primary ms-2">
        {{ getSeatLabel(seatingPlanService.seatingPlan, selectedSeat) }}
      </span>
    </div>
    <div *ngFor="let category of reservationService.reservation.categories">
      <ng-container *ngIf="category.value > 0">
        <b>{{ category.value }}</b> &times; {{ category.label }} |&nbsp;<span
          [innerHTML]="category.price | currency: 'CHF':'code':'1.2-2':'de-CH'"></span>
      </ng-container>
    </div>
    <p>
      Anzahl Pl&auml;tze insgesamt: <b>{{ reservationService.reservation.selectedSeats.length }}</b>
    </p>
    <p>
      <b>Total:</b>&nbsp;
      <span [innerHTML]="reservationService.reservation.total | currency: 'CHF':'code':'1.2-2':'de-CH'"></span>
    </p>

    <hr />
    <h5>Kontaktdaten</h5>
    <h6>Postanschrift</h6>

    <p class="form-control-static ps-4">
      {{ reservationService.reservation.address.firstname }} {{ reservationService.reservation.address.lastname }}<br />
      {{ reservationService.reservation.address.address }}<br />
      {{ reservationService.reservation.address.zip }} {{ reservationService.reservation.address.city }}
    </p>

    <h6>Telefonnummer</h6>

    <p class="form-control-static ps-4">
      <span *ngIf="reservationService.reservation.address.phone">
        {{ reservationService.reservation.address.phone }}
      </span>
      <span *ngIf="!reservationService.reservation.address.phone"> (keine angegeben) </span>
    </p>

    <hr />
    <h5>Reservationsbest&auml;tigung</h5>
    <h6>Die Reservationsbest&auml;tigung wird an folgende E-Mail-Adresse versandt:</h6>

    <p class="form-control-static ps-4">
      <span *ngIf="reservationService.reservation.address.email">
        {{ reservationService.reservation.address.email }}
      </span>
      <span *ngIf="!reservationService.reservation.address.email"> (keine angegeben) </span>
    </p>

    <hr *ngIf="reservationService.reservation.address.notes" />
    <h5 *ngIf="reservationService.reservation.address.notes">Bemerkungen</h5>

    <p *ngIf="reservationService.reservation.address.notes" class="form-control-static ps-4">
      {{ reservationService.reservation.address.notes }}
    </p>

    <p
      class="p-4 bg-info rounded-box"
      *ngIf="reservationService.reservation.production.disclaimerHTML"
      [innerHTML]="reservationService.reservation.production.disclaimerHTML"></p>

    <nav class="row">
      <div class="col-sm nav-left">
        <button type="button" class="btn btn-default" (click)="navigate(addressLinkRoute)" [disabled]="reservationService.isBooking">
          <i class="fa fa-caret-left"></i> Zur&uuml;ck
        </button>
      </div>
      <div class="col-sm nav-right">
        <button
          type="button"
          class="btn btn-primary"
          (click)="book()"
          [disabled]="
            reservationService.reservation.performance === null ||
            reservationService.reservation.selectedSeats.length <= 0 ||
            reservationService.isBooking
          ">
          Reservation absenden
        </button>
      </div>
    </nav>
  </div>
</div>
