<div *ngIf="performances && performances.length !== 0" class="mb-4">
  <h4><span class="step-number">1</span>Auff&uuml;hrungsdatum</h4>
  <div class="btn-group" dropdown>
    <button
      id="performanceDropdownButton"
      type="button"
      class="btn btn-secondary dropdown-toggle ms-4"
      dropdownToggle
      aria-controls="performanceDropdownBasic">
      {{
        reservationService.reservation.performance
          ? (reservationService.reservation.performance.date | dateTimeFromFormat: "yyyy-MM-dd HH:mm:ss" | date: "d. MMMM yyyy | HH:mm 'Uhr'")
          : "Bitte ausw&auml;hlen"
      }}
    </button>
    <ul id="performanceDropdownBasic" class="dropdown-menu" role="menu" aria-labelledby="performanceDropdownButton" *dropdownMenu>
      <li
        class="dropdown-item"
        [class.active]="selectedPerformance === performance.id"
        (click)="selectPerformance(performance.id)"
        role="menuitem"
        *ngFor="let performance of performances">
        {{ performance.date | dateTimeFromFormat: "yyyy-MM-dd HH:mm:ss" | date: "d. MMMM yyyy | HH:mm 'Uhr'" }}
      </li>
    </ul>
  </div>
  <div *ngIf="selectedPerformance === 0" class="p-2 mt-2 mb-2 bg-warning rounded-box">
    Bitte w&auml;hlen Sie die gew&uuml;nschte Auff&uuml;hrung aus.
  </div>
</div>
<p class="p-3 mt-2 mb-2 bg-warning" *ngIf="performances && performances.length === 0">
  <span class="fa fa-exclamation-circle"></span>
  Die Online-Reservation f&uuml;r diese Veranstaltung ist geschlossen.
</p>
