<div id="reservationWizard" *ngIf="reservationService.reservation">
  <nav *ngIf="reservationService.reservation.production" class="row">
    <div class="col-sm-4 nav-left">
      <span>{{title}}</span>
    </div>
    <div class="col-sm-8 nav-right">
      <button class="btn btn-link"
              (click)="navigate(homeLinkRoute)">
        Home
      </button>
      <button class="btn btn-link"
              *ngIf="reservationService.reservation.production.contact"
              (click)="navigate(contactLinkRoute)">
        Kontakt
      </button>
      <button class="btn btn-link"
              *ngIf="reservationService.isAdmin"
              (click)="navigate(adminLinkRoute)">
        Administration
      </button>
      <button class="btn btn-link"
              *ngIf="reservationService.isAdmin && authService.isAuthenticated"
              (click)="navigate(logoutLinkRoute)">
        {{authService.session.name}} abmelden
      </button>
    </div>
  </nav>
  <router-outlet></router-outlet>
</div>
