<app-progress-indicator [value]="2" [max]="5"></app-progress-indicator>
<h4><span class="step-number">3</span>Kategorien ausw&auml;hlen</h4>

<p>
  Sie haben insgesamt <b>{{ reservationService.reservation.selectedSeats.length }}</b> Pl&auml;tze ausgew&auml;hlt.
</p>
<p>W&auml;hlen Sie nachfolgend die gew&uuml;nschte Kategorie und/oder Erm&auml;ssigung aus.</p>

<div>
  <table>
    <ng-container *ngFor="let rangeSet of selectedRangeSets">
      <tr *ngIf="rangeSet.label">
        <td colspan="3" style="padding-top: 1em">
          <p>
            <strong>{{ rangeSet.label }}</strong>
            ({{ rangeSet.selectedSeatsCount }} Pl&auml;tze ausgew&auml;hlt)
          </p>
        </td>
      </tr>
      <tr *ngFor="let category of rangeSet.categories">
        <td style="padding-top: 0em; padding-left: 2em">
          {{ category.shortLabel }}
          <span *ngIf="category.description"><br />{{ category.description }}</span>
        </td>
        <td style="padding-bottom: 0.4em; padding-right: 1em; padding-left: 1em">
          <div class="btn-group" dropdown>
            <button
              id="categoryDropdownButton"
              type="button"
              class="btn btn-secondary dropdown-toggle"
              [disabled]="rangeSet.categories.length <= 1"
              [ngClass]="'fill-style-' + category.fillStyle"
              dropdownToggle
              aria-controls="categoryDropdownBasic">
              {{ category.value }}
            </button>
            <ul id="categoryDropdownBasic" class="dropdown-menu" role="menu" aria-labelledby="categoryDropdownButton" *dropdownMenu>
              <li
                class="dropdown-item"
                [class.active]="category.value === i"
                (click)="reservationService.chooseCategory(category, rangeSet, i)"
                role="menuitem"
                *ngFor="let number of getNumber(rangeSet.selectedSeatsCount + 1 || 0); let i = index">
                {{ i }}
              </li>
            </ul>
          </div>
        </td>
        <td>&times;&nbsp;<span [innerHTML]="category.price | currency: 'CHF':'code':'1.2-2':'de-CH'"></span></td>
      </tr>
    </ng-container>
  </table>
  <div
    class="p-2 bg-warning rounded-box"
    *ngIf="reservationService.reservation.selectedSeats.length > reservationService.reservation.selectedCategories">
    <p *ngIf="reservationService.reservation.selectedSeats.length === 1">Bitte w&auml;hlen Sie eine Kategorie aus.</p>
    <p *ngIf="reservationService.reservation.selectedSeats.length > 1">
      Bitte w&auml;hlen Sie f&uuml;r alle {{ reservationService.reservation.selectedSeats.length }} Pl&auml;tze eine Kategorie aus.
    </p>
  </div>
  <div
    class="p-2 bg-danger text-white rounded-box"
    *ngIf="reservationService.reservation.selectedSeats.length < reservationService.reservation.selectedCategories">
    <p *ngIf="reservationService.reservation.selectedSeats.length === 1">
      Sie haben <b>1</b> Platz ausgew&auml;hlt, aber Kategorien f&uuml;r <b>{{ reservationService.reservation.selectedCategories }}</b> Pl&auml;tze
      ausgesucht. Bitte w&auml;hlen Sie nur <b>1</b> Kategorie aus.
    </p>
    <p *ngIf="reservationService.reservation.selectedSeats.length > 1">
      Sie haben <b>{{ reservationService.reservation.selectedSeats.length }}</b> Pl&auml;tze ausgew&auml;hlt, aber Kategorien f&uuml;r
      <b>{{ reservationService.reservation.selectedCategories }}</b> Pl&auml;tze ausgesucht. Bitte w&auml;hlen Sie nur
      <b>{{ reservationService.reservation.selectedSeats.length }}</b> Kategorien aus.
    </p>
    <p>
      Wenn Sie die Anzahl Pl&auml;tze &auml;ndern m&ouml;chten, gehen Sie bitte zum letzten Schritt zur&uuml;ck und w&auml;hlen Sie
      <span *ngIf="reservationService.reservation.selectedCategories - reservationService.reservation.selectedSeats.length === 1">
        einen weiteren Platz aus.
      </span>
      <span *ngIf="reservationService.reservation.selectedCategories - reservationService.reservation.selectedSeats.length > 1">
        {{ reservationService.reservation.selectedCategories - reservationService.reservation.selectedSeats.length }}&nbsp;weitere Pl&auml;tze aus.
      </span>
    </p>
  </div>
</div>

<p>Total: <span class="total-price" [innerHTML]="reservationService.reservation.total | currency: 'CHF':'code':'1.2-2':'de-CH'"></span></p>

<nav class="row">
  <div class="col-sm nav-left">
    <button class="btn btn-default" (click)="navigate(seatsLinkRoute)"><i class="fa fa-caret-left"></i> Zur&uuml;ck</button>
  </div>
  <div class="col-sm nav-right">
    <button
      type="button"
      class="btn btn-primary"
      (click)="navigate(addressLinkRoute)"
      [disabled]="
        reservationService.reservation.performance === null ||
        reservationService.reservation.selectedSeats.length <= 0 ||
        reservationService.reservation.selectedCategories !== reservationService.reservation.selectedSeats.length
      ">
      Weiter <i class="fa fa-caret-right"></i>
    </button>
  </div>
</nav>
