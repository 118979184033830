<app-progress-indicator [value]="3" [max]="5"></app-progress-indicator>
<h4><span class="step-number">4</span>Kontaktadresse</h4>

<form name="addressForm" [formGroup]="addressForm">
  <fieldset>
    <div class="row mb-2 p-0">
      <div class="col">
        <input
          type="text"
          id="firstname"
          name="firstname"
          class="form-control"
          placeholder="Vorname"
          autocomplete="given-name"
          formControlName="firstname" />
      </div>
      <div class="col">
        <input
          type="text"
          id="lastname"
          name="lastname"
          class="form-control"
          placeholder="Nachname"
          autocomplete="family-name"
          formControlName="lastname" />
      </div>
    </div>
    <div class="row mb-2 p-0">
      <div class="col">
        <input
          type="text"
          id="address"
          name="address"
          class="form-control"
          placeholder="Adresse und Hausnummer"
          autocomplete="street-address"
          formControlName="address" />
      </div>
    </div>
    <div class="row mb-4 p-0">
      <div class="col">
        <input
          type="number"
          id="zip"
          name="zip"
          class="form-control"
          placeholder="PLZ"
          autocomplete="postal-code"
          formControlName="zip"
          width="150" />
      </div>
      <div class="col">
        <input type="text" id="city" name="city" class="form-control" placeholder="Wohnort" autocomplete="address-level2" formControlName="city" />
      </div>
    </div>

    <div class="mb-4">
      <label>Empf&auml;nger der Reservationsbest&auml;tigung</label>
      <div class="input-group">
        <div class="input-group-text"><span class="fa fa-envelope"></span></div>
        <input type="text" id="email" name="email" class="form-control" placeholder="E-Mail-Adresse" autocomplete="email" formControlName="email" />
      </div>
    </div>
    <div *ngIf="reservationService.reservation.production.announce">
      <input
        type="checkbox"
        id="announceNextProduction"
        name="announceNextProduction"
        autocomplete="off"
        formControlName="announceNextProduction"
        width="30" />&nbsp;
      <label for="announceNextProduction"> Sie d&uuml;rfen mich per E-Mail &uuml;ber die n&auml;chste Veranstaltung informieren </label>
    </div>

    <div class="mb-4">
      <label>Telefonnummer f&uuml;r allf&auml;llige R&uuml;ckfragen</label>
      <div class="input-group">
        <div class="input-group-text"><span class="fa fa-phone"></span></div>
        <input
          type="text"
          id="phone"
          name="phone"
          class="form-control"
          placeholder="Telefonnummer"
          autocomplete="tel-national"
          formControlName="phone" />
      </div>
      <small class="error">
        <i>Optional:</i> Bitte geben Sie eine Telefonnummer ein, unter der Sie am besten erreichbar sind.<br />
        Wir verwenden diese Angabe nur f&uuml;r allf&auml;llige R&uuml;ckfragen zu Ihrer Reservation.
      </small>
    </div>

    <div class="mb-4">
      <label for="notes">Bemerkungen</label>
      <textarea id="notes" name="notes" class="form-control" autocomplete="off" formControlName="notes" rows="4"></textarea>
    </div>
  </fieldset>
</form>

<div class="p-2 mb-4 bg-warning rounded-box" *ngIf="addressForm.invalid && !reservationService.isAdmin">
  <p>Ihre Angaben sind noch nicht vollst&auml;ndig:</p>
  <ul>
    <li class="error" *ngIf="firstname.errors?.required || firstname.errors?.minlength || lastname.errors?.required || lastname.errors?.minlength">
      Bitte geben Sie Ihren <strong>Vor- und Nachnamen</strong> ein.
    </li>
    <li
      class="error"
      *ngIf="
        address.errors?.required ||
        address.errors?.minlength ||
        zip.invalid ||
        zip.errors?.required ||
        zip.errors?.minlength ||
        city.errors?.required ||
        city.errors?.minlength
      ">
      Bitte geben Sie Ihre vollst&auml;ndige <strong>Adresse</strong> ein.<br />
      Wir verwenden diese Angabe ausschliesslich, um nicht abgeholte Tickets in Rechnung zu stellen.
    </li>
    <li class="error" *ngIf="email.errors?.required">
      Bitte geben Sie Ihre <strong>E-Mail-Adresse</strong> an. Sie erhalten eine Reservationsbest&auml;tigung an diese Adresse.
    </li>
    <li class="error" *ngIf="email.invalid && !email.errors?.required">
      Die eingegebene <strong>E-Mail-Adresse</strong> ist ung&uuml;ltig oder unvollst&auml;ndig.
    </li>
  </ul>
</div>

<nav class="row">
  <div class="col-sm nav-left">
    <button type="button" class="btn btn-default" (click)="back()"><i class="fa fa-caret-left"></i> Zur&uuml;ck</button>
  </div>
  <div class="col-sm nav-right">
    <button
      type="button"
      class="btn btn-primary"
      (click)="continue()"
      [disabled]="
        reservationService.reservation.performance === null ||
        reservationService.reservation.selectedSeats.length <= 0 ||
        (addressForm.invalid && !reservationService.isAdmin)
      ">
      Weiter <i class="fa fa-caret-right"></i>
    </button>
  </div>
</nav>
