import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';
import {AdminService} from '../../../services/admin.service';
import {ReservationService} from '../../../services/reservation.service';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent implements OnInit, OnDestroy {

  baseRouteSubscription: Subscription;
  public seatsLinkRoute: string[];

  constructor(public reservationService: ReservationService,
              private adminService: AdminService) {
  }

  ngOnInit() {
    this.baseRouteSubscription = this.adminService.routeBaseChanged.subscribe(routeBase => {
      this.seatsLinkRoute = routeBase.concat('seats');
    });
    this.seatsLinkRoute = this.adminService.getRoute('seats');
  }

  ngOnDestroy() {
    if (this.baseRouteSubscription) {
      this.baseRouteSubscription.unsubscribe();
    }
  }
}
