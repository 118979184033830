<div *ngIf="reservationService.reservation && reservationService.reservation.production">
  <div
    class="jumbotron bg-secondary rounded-box"
    [class.ok]="reservationService.reservation.production.title"
    [style.background-image]="
      reservationService.reservation.production.id ? 'url(' + apiUrl + 'mainimage/' + reservationService.reservation.production.id + ')' : 'none'
    ">
    <p class="lead"></p>
    <p>
      <button type="button" class="btn btn-primary" (click)="reservationService.reset(true, seatsLinkRoute)">
        <i class="fa fa-ticket"></i> Pl&auml;tze reservieren
      </button>
      <a *ngIf="reservationService.reservation.production.website" class="btn btn-light" [href]="reservationService.reservation.production.website">
        Zur&uuml;ck zur Homepage
      </a>
    </p>
  </div>
  <div class="row disclaimer" *ngIf="reservationService.reservation.production.disclaimerHTML">
    <div class="col">
      <div class="p-4 bg-secondary text-white rounded-box">
        <h4>Hinweis</h4>
        <p [innerHTML]="reservationService.reservation.production.disclaimerHTML"></p>
      </div>
    </div>
  </div>
</div>
