import {Injectable} from '@angular/core';
import {SeatingPlan} from '../entities/seatingPlan';

@Injectable()
export class SeatLabelService {
  private static HIDDEN = 1;
  private static SKIPPED = 2;

  constructor() {}

  static gap(seatingPlan, planDimension: number, index: number, property: string): number {
    for (const gapIndex in seatingPlan.gaps[property]) {
      if (seatingPlan.gaps[property].hasOwnProperty(gapIndex) && seatingPlan.gaps[property][gapIndex].index === index) {
        return (planDimension * seatingPlan.gaps[property][gapIndex].size) / 100;
      }
    }

    return 0;
  }

  static summedGap(seatingPlan, planDimension: number, index: number, property: string): number {
    let gapSum = 0;
    let i = index;

    while (i > 0) {
      gapSum += SeatLabelService.gap(seatingPlan, planDimension, i, property);
      i -= 1;
    }

    return gapSum;
  }

  static summedColumnGap(seatingPlan, planWidth: number, column: number): number {
    return SeatLabelService.summedGap(seatingPlan, planWidth, column, 'c');
  }

  static summedRowGap(seatingPlan, planHeight: number, row: number): number {
    return SeatLabelService.summedGap(seatingPlan, planHeight, row, 'r');
  }

  static getRowColumn(seatingPlan, exceptions, realSeatId, labelingOrder) {
    let r = 1;
    let c = realSeatId;
    let lastRowEndId;
    let rowStartId;
    let rowEndId;
    let nextRowStartId;

    switch (labelingOrder) {
      case 2: // opposite
        // TODO Fix: exception[i] means skipped seats up to and INCLUDING seat i.
        rowStartId = 1;
        nextRowStartId = seatingPlan.seatsPerRow * 2 - exceptions[seatingPlan.seatsPerRow * 2];
        let seatsInCurrentTwoRows = nextRowStartId - rowStartId + 1;

        while (c > seatsInCurrentTwoRows) {
          c -= seatsInCurrentTwoRows;
          r += 1;

          rowStartId = nextRowStartId;
          nextRowStartId = r * 2 * seatingPlan.seatsPerRow - exceptions[rowStartId + 2 * seatingPlan.seatsPerRow];
          seatsInCurrentTwoRows = nextRowStartId - rowStartId;
        }

        break;
      case 3: // opposite inverse
        break;
      case 0: // default
      case 1: // inverse
      default:
        lastRowEndId = 0;
        rowEndId = lastRowEndId + seatingPlan.seatsPerRow;
        let seatsInCurrentRow = seatingPlan.seatsPerRow - exceptions[rowEndId];

        while (c > seatsInCurrentRow) {
          c -= seatsInCurrentRow;
          r += 1;

          lastRowEndId += seatingPlan.seatsPerRow;
          rowEndId = lastRowEndId + seatingPlan.seatsPerRow;
          seatsInCurrentRow = seatingPlan.seatsPerRow - (exceptions[rowEndId] - exceptions[lastRowEndId]);
        }

        break;
    }

    return [r, c];
  }

  static getRow(seatingPlan, exceptions, realSeatId: number) {
    return SeatLabelService.getRowColumn(seatingPlan, exceptions, realSeatId, seatingPlan.labelingOrder)[0];
  }

  static getColumn(seatingPlan, exceptions, realSeatId: number) {
    return SeatLabelService.getRowColumn(seatingPlan, exceptions, realSeatId, seatingPlan.labelingOrder)[1];
  }

  static isSeatHidden(seatingPlan, rawSeatId): boolean {
    return SeatLabelService.checkExceptionType(seatingPlan, rawSeatId, SeatLabelService.HIDDEN);
  }

  static isSeatSkipped(seatingPlan, rawSeatId): boolean {
    return SeatLabelService.checkExceptionType(seatingPlan, rawSeatId, SeatLabelService.SKIPPED);
  }

  static checkExceptionType(seatingPlan, rawSeatId, exceptionType): boolean {
    if (seatingPlan.x['s' + rawSeatId]) {
      return seatingPlan.x['s' + rawSeatId] === exceptionType;
    }
    return false;
  }

  static getSeatLabel(seatingPlan: SeatingPlan, exceptions: any, realSeatId: number) {
    if (seatingPlan) {
      switch (seatingPlan.labelingStrategy) {
        case 1: // numeric row
          return (
            'Reihe ' +
            SeatLabelService.getRow(seatingPlan, exceptions, realSeatId) +
            ', Sitz ' +
            SeatLabelService.getColumn(seatingPlan, exceptions, realSeatId)
          );
        case 2: // letter row
          const row = SeatLabelService.getRow(seatingPlan, exceptions, realSeatId);
          const rowLetter = row <= 26 ? String.fromCharCode(64 + row) : 'A' + String.fromCharCode(38 + row);
          return 'Reihe ' + rowLetter + ', Sitz ' + SeatLabelService.getColumn(seatingPlan, exceptions, realSeatId);
        case 3: // numeric row combined
          return (
            'Sitz ' +
            (100 * SeatLabelService.getRow(seatingPlan, exceptions, realSeatId) + SeatLabelService.getColumn(seatingPlan, exceptions, realSeatId))
          );
        case 0:
        default:
          return realSeatId;
      }
    } else {
      return '...';
    }
  }

  static getRawSeatId(seatingPlan, r: number, c: number, labelingOrder) {
    let rawSeatId = 0;

    switch (labelingOrder) {
      case 1: // inverse
        rawSeatId = seatingPlan.seatsPerRow * seatingPlan.rows - SeatLabelService.getRawSeatId(seatingPlan, r, c, 0) + 1;
        break;
      case 2: // opposite
        if (r % 2 === 1) {
          // return odd numbers
          rawSeatId = (r - 1) * seatingPlan.seatsPerRow + 2 * c - 1;
        } else {
          // return even numbers
          rawSeatId = r * seatingPlan.seatsPerRow + 2 * (c - seatingPlan.seatsPerRow);
        }
        break;
      case 3: // opposite inverse
        rawSeatId = seatingPlan.seatsPerRow * seatingPlan.rows - SeatLabelService.getRawSeatId(seatingPlan, r, c, 2) + 1;
        break;
      case 0:
      default:
        rawSeatId = (r - 1) * seatingPlan.seatsPerRow + c;
        break;
    }

    return rawSeatId;
  }

  /**
   * Transforms the given row and column index to a real seat ID (i.e. accounting for skipped seats).
   */
  static getRealSeatId(seatingPlan, r: number, c: number, labelingOrder, exceptions) {
    let realSeatId = SeatLabelService.getRawSeatId(seatingPlan, r, c, labelingOrder);

    if (exceptions) {
      realSeatId -= exceptions[realSeatId];
    }

    return realSeatId;
  }
}
