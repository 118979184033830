import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/observable/of';
import {tap} from 'rxjs/operators';
import * as globals from '../globals';
import * as d3 from 'd3';
import {SeatLabelService} from './seat-label.service';
import {Performance} from '../entities/performance';
import {SeatingPlan} from '../entities/seatingPlan';

@Injectable()
export class SeatingPlanService {

  seatingPlan: SeatingPlan;

  exceptions: {};

  // Static values
  planWidth = 500;
  planHeight = 600;
  hPadding = 0;
  vPadding = 0;
  hOffset = 0;
  vOffset = 40;

  // Dynamically calculated variables for seat and table sizes
  seatWidth = 0;
  seatHeight = 0;
  tableWidth = 0;
  tableHeight = 0;

  hasTables = false;
  rotatePlan = false;

  constructor(private http: HttpClient,
              private seatLabelService: SeatLabelService) {
  }

  getSeatingPlan(performance: Performance): Observable<SeatingPlan> {
    if (performance) {
      if (!this.seatingPlan || parseInt(this.seatingPlan.performance, 10) !== performance.id) {
        return this.http.get<SeatingPlan>(globals.apiUrl + 'seatingplan/' + performance.id)
          .pipe(
            tap(seatingPlan => {
              this.seatingPlan = seatingPlan;
              this.prepareSeatingPlan();
            }));
      } else {
        return of(this.seatingPlan);
      }
    } else {
      return of(undefined);
    }
  }

  prepareSeatingPlan() {
    this.planWidth = Math.max(200, d3.select('#reservationWizard').node().getBoundingClientRect().width - 50) || 600;
    this.planHeight = this.planWidth * this.seatingPlan.heightToWidthRatio;
    this.hPadding = this.planWidth * this.seatingPlan.hPadding / 100;
    this.vPadding = this.planHeight * this.seatingPlan.vPadding / 100;
    this.hasTables = (this.seatingPlan.tablesPerRow !== null && this.seatingPlan.tablesPerRow > 0);
    this.rotatePlan = (this.seatingPlan.rowAlignment === 1);
    this.seatingPlan.numberOfRows = (this.hasTables
      ? 2 * this.seatingPlan.rows
      : this.seatingPlan.rows);
    this.seatingPlan.seatsPerRow = (this.hasTables
      ? this.seatingPlan.tablesPerRow * this.seatingPlan.seatsPerTable / 2
      : this.seatingPlan.seatsPerRow);
    this.exceptions = (() => {
      let skips = 0;
      const map = {};
      for (let i = 1; i <= this.seatingPlan.seatsPerRow * this.seatingPlan.numberOfRows; i++) {
        if (SeatLabelService.isSeatSkipped(this.seatingPlan, i)) {
          skips += 1;
        }
        map[i] = skips;
      }
      // This map maps each raw seat ID to the number of seats that were skipped up to this particular seat ID.
      return map;
    })();

    if (this.hasTables) {
      if (this.rotatePlan) {
        this.seatWidth = (this.planWidth - SeatLabelService.summedRowGap(this.seatingPlan,
          this.planWidth, this.seatingPlan.numberOfRows) -
          this.hOffset + ((1 - (this.seatingPlan.numberOfRows / 2)) * this.hPadding)) /
          (this.seatingPlan.numberOfRows + (this.seatingPlan.numberOfRows * this.seatingPlan.tableSizeRatio / 2));
        this.seatHeight = (this.planHeight - SeatLabelService.summedColumnGap(this.seatingPlan,
          this.planHeight, this.seatingPlan.seatsPerRow) -
          this.vOffset + ((1 - this.seatingPlan.seatsPerRow) * this.vPadding)) /
          this.seatingPlan.seatsPerRow;

        this.tableWidth = this.seatWidth * this.seatingPlan.tableSizeRatio;
        this.tableHeight = ((this.seatHeight + this.vPadding) * this.seatingPlan.seatsPerTable / 2) - this.vPadding;
      } else {
        this.seatWidth = (this.planWidth - SeatLabelService.summedColumnGap(this.seatingPlan,
          this.planWidth, this.seatingPlan.seatsPerRow) -
          this.hOffset + ((1 - this.seatingPlan.seatsPerRow) * this.hPadding)) /
          this.seatingPlan.seatsPerRow;
        this.seatHeight = (this.planHeight - SeatLabelService.summedRowGap(this.seatingPlan,
          this.planHeight, this.seatingPlan.numberOfRows) -
          this.vOffset + ((1 - (this.seatingPlan.numberOfRows / 2)) * this.vPadding)) /
          (this.seatingPlan.numberOfRows + (this.seatingPlan.numberOfRows * this.seatingPlan.tableSizeRatio / 2));

        this.tableWidth = ((this.seatWidth + this.hPadding) * this.seatingPlan.seatsPerTable / 2) - this.hPadding;
        this.tableHeight = this.seatHeight * this.seatingPlan.tableSizeRatio;
      }
    } else if (this.rotatePlan) {
      this.seatWidth = (this.planWidth - SeatLabelService.summedRowGap(this.seatingPlan,
        this.planWidth, this.seatingPlan.numberOfRows) -
        this.hOffset + ((1 - this.seatingPlan.numberOfRows) * this.hPadding)) / this.seatingPlan.numberOfRows;
      this.seatHeight = (this.planHeight - SeatLabelService.summedColumnGap(this.seatingPlan,
        this.planHeight, this.seatingPlan.seatsPerRow) -
        this.vOffset + ((1 - this.seatingPlan.seatsPerRow) * this.vPadding)) / this.seatingPlan.seatsPerRow;
    } else {
      this.seatWidth = (this.planWidth - SeatLabelService.summedColumnGap(this.seatingPlan,
        this.planWidth, this.seatingPlan.seatsPerRow) -
        this.hOffset + ((1 - this.seatingPlan.seatsPerRow) * this.hPadding)) / this.seatingPlan.seatsPerRow;
      this.seatHeight = (this.planHeight - SeatLabelService.summedRowGap(this.seatingPlan,
        this.planHeight, this.seatingPlan.numberOfRows) -
        this.vOffset + ((1 - this.seatingPlan.numberOfRows) * this.vPadding)) / this.seatingPlan.numberOfRows;
    }
  }
}
