import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {AdminService} from '../../../services/admin.service';
import {AuthService} from '../../../services/auth.service';
import {PerformanceService} from '../../../services/performance.service';
import {ReservationService} from '../../../services/reservation.service';
import {SeatLabelService} from '../../../services/seat-label.service';
import {SeatingPlanService} from '../../../services/seating-plan.service';
import {Address} from '../../../entities/address';
import {Performance} from '../../../entities/performance';

@Component({
  selector: 'app-reservations',
  templateUrl: './reservations.component.html',
  styleUrls: ['./reservations.component.css']
})
export class ReservationsComponent implements OnInit, OnDestroy {
  performances: Performance[];
  selectedAddress: Address;
  searched = false;
  performancesSubscription: Subscription;

  constructor(
    public adminService: AdminService,
    public reservationService: ReservationService,
    private authService: AuthService,
    private seatingPlanService: SeatingPlanService,
    private performanceService: PerformanceService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (!this.reservationService.reservation.production.performances) {
      this.performancesSubscription = this.performanceService
        .getPerformances(this.adminService.adminToken, this.reservationService.reservation.production)
        .subscribe(performances => {
          this.performances = performances;
        });
    }

    this.adminService.admin.production = this.reservationService.reservation.production;
  }

  editResevation(address: Address) {
    this.adminService.editReservation(this.reservationService.reservation, address /*, SeatLabelService.getSeatLabel */);
  }

  cancelReservation(address: Address) {
    this.adminService.cancelReservation(address, this.changeDetectorRef);
  }

  getPerformanceById(performanceId: number): Performance {
    for (let performanceIndex = 0; performanceIndex < this.performances.length; performanceIndex++) {
      if (this.performances[performanceIndex].id === performanceId) {
        return this.performances[performanceIndex];
      }
    }
  }

  getReservationDetails(address: Address) {
    if (address !== this.selectedAddress) {
      if (!this.selectedAddress) {
        address.showDetails = true;
      } else {
        this.selectedAddress.showDetails = false;
        address.showDetails = true;
      }
    } else {
      address.showDetails = !address.showDetails;
    }

    this.selectedAddress = address;
    const subscriber = this.adminService.getReservationDetails(address);
    if (subscriber) {
      const reservationDetailsSubscription = subscriber.subscribe(data => {
        if (!this.seatingPlanService.seatingPlan) {
          const performancesSubscription = this.performanceService
            .getPerformances(this.adminService.adminToken, this.reservationService.reservation.production)
            .subscribe(performances => {
              this.performances = performances;

              const performance = this.getPerformanceById(data.s[0].p);
              const seatingPlanSubscription = this.seatingPlanService.getSeatingPlan(performance).subscribe((/* seatingPlan */) => {
                // Subscription is required to automatically refresh the seat labels when the seating plan has been loaded.
                this.changeDetectorRef.markForCheck();
                if (seatingPlanSubscription) {
                  seatingPlanSubscription.unsubscribe();
                }
              });

              performancesSubscription.unsubscribe();
            });
        }

        this.changeDetectorRef.markForCheck();
        reservationDetailsSubscription.unsubscribe();
      });
    }
  }

  getSeatLabel(seatId) {
    if (this.seatingPlanService.seatingPlan) {
      return SeatLabelService.getSeatLabel(this.seatingPlanService.seatingPlan, this.seatingPlanService.exceptions, seatId);
    }
  }

  search() {
    if (this.authService.isAuthenticated) {
      const searchSubscription = this.adminService.search().subscribe(() => {
        this.searched = true;
        this.changeDetectorRef.markForCheck();
        searchSubscription.unsubscribe();
      });
    } else {
      this.router.navigate(this.adminService.getRoute('admin', 'login'));
    }
  }

  ngOnDestroy() {
    if (this.performancesSubscription) {
      this.performancesSubscription.unsubscribe();
    }
  }
}
