<app-progress-indicator [value]="reservationService.reservation.performance ? 1 : 0"
                        [max]="5"></app-progress-indicator>
<app-performance-selection></app-performance-selection>
<app-seating-plan></app-seating-plan>
<nav class="row">
  <div class="col-sm nav-left">
    <button class="btn btn-default"
            (click)="navigate(homeLinkRoute)">
      <i class="fa fa-caret-left"></i> Zur&uuml;ck
    </button>
  </div>
  <div class="col-sm nav-right">
    <button class="btn btn-primary"
            [disabled]="!reservationService.reservation.selectedSeats.length"
            (click)="navigate(optionsLinkRoute)">
      Weiter <i class="fa fa-caret-right"></i>
    </button>
  </div>
</nav>
