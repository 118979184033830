<div>
  <div class="modal-header">
    <h3 class="modal-title">Stornieren</h3>
  </div>
  <div class="modal-body">
    <p>M&ouml;chten Sie die Reservation wirklich stornieren?</p>
    <p>Die Reservation wird gel&ouml;scht und die Pl&auml;tze werden wieder freigegeben.</p>
  </div>
  <div class="modal-footer">
    <button class="btn btn-danger" (click)="ok()">L&ouml;schen</button>
    <button class="btn btn-default" (click)="cancel()">Abbrechen</button>
  </div>
</div>
