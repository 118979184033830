import { Component } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

@Component({
  selector: "app-outdated-browser-modal",
  templateUrl: "./outdated-browser-modal.component.html",
  providers: [BsModalService],
})
export class OutdatedBrowserModalComponent {
  public modalService: BsModalService;

  constructor(private modalRef: BsModalRef) {}

  updateBrowser(): void {
    this.modalService.setDismissReason("ok");
    this.modalRef.hide();
    window.location.href = "http://outdatedbrowser.com/de";
  }

  back(): void {
    window.history.back();
    setTimeout(window.close, 500);
  }

  dismiss(): void {
    this.modalService.setDismissReason("cancel");
    this.modalRef.hide();
  }
}
