<div *ngIf="reservationService.reservation.performance && reservationService.seatingPlan">
  <h4><span class="step-number">2</span>Sitzpl&auml;tze</h4>
  <div id="seatingPlanContainer" [ngClass]="{editing: reservationService.reservation.editingToken, loading: isLoadingSeatingPlan}">
    <svg id="seatingPlan"></svg>
  </div>
  <p class="p-3 mb-2 bg-info rounded-box" *ngIf="reservationService.reservation.selectedSeats.length > 0">
    Sie haben die folgenden Pl&auml;tze ausgew&auml;hlt:
    <span class="d-inline-block me-2 badge bg-light text-dark" *ngFor="let selectedSeat of reservationService.reservation.selectedSeats">
      {{ getSeatLabel(selectedSeat) }}
    </span>
  </p>
  <p class="p-3 mb-2 bg-warning rounded-box" *ngIf="reservationService.reservation.selectedSeats.length <= 0">
    <span class="fa fa-exclamation-circle"></span>
    Bitte w&auml;hlen Sie die gew&uuml;nschten Sitzpl&auml;tze aus, indem Sie sie im obigen Sitzplan anklicken.
  </p>
</div>
