import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import {catchError, tap} from 'rxjs/operators';
import {of} from 'rxjs/observable/of';
import * as globals from '../globals';
import {AdminService} from './admin.service';
import {ReservationService} from './reservation.service';
import {Production} from '../entities/production';
import {Title} from '@angular/platform-browser';

@Injectable()
export class ProductionService {
  constructor(
    private reservationService: ReservationService,
    private adminService: AdminService,
    private http: HttpClient,
    private router: Router,
    private titleService: Title
  ) {}

  getProduction(productionId: string): Observable<any> {
    return this.http.get<Production>(globals.apiUrl + 'production/' + productionId).pipe(
      tap(production => {
        production.id = productionId;
        this.reservationService.selectProduction(production);
        this.titleService.setTitle(production.title + ' - Platzreservation');
      }),
      catchError(this.handleError('getProduction', []))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.router.navigate(this.adminService.getRoute());

      // TODO: send the error to remote logging infrastructure
      // console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
