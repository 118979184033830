export class Address {
  id: number;

  firstname: string;
  lastname: string;

  address: string;
  zip: string;
  city: string;

  email: string;
  phone: string;

  seats: number[];
  options: number[];
  announceNextProduction: boolean;
  notes: string;

  showDetails: boolean;
  details: boolean;
}
