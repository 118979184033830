import {NgModule, LOCALE_ID} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import localeDeCh from '@angular/common/locales/de-ch';
import {FormsModule} from '@angular/forms';
import {ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LuxonModule} from 'luxon-angular';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, RecaptchaModule, RecaptchaFormsModule} from 'ng-recaptcha';
import {AlertModule} from 'ngx-bootstrap/alert';
import {BsDatepickerModule, BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ModalModule} from 'ngx-bootstrap/modal';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';

import {AdminService} from './services/admin.service';
import {CategoryService} from './services/category.service';
import {MessageService} from './services/message.service';
import {PerformanceService} from './services/performance.service';
import {ProductionService} from './services/production.service';
import {ReservationService} from './services/reservation.service';
import {SeatLabelService} from './services/seat-label.service';
import {SeatingPlanService} from './services/seating-plan.service';

import {PerformanceSelectionComponent} from './components/reservation/performance-selection/performance-selection.component';
import {ReservationComponent} from './components/reservation/reservation.component';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {LandingPageComponent} from './components/reservation/landing-page/landing-page.component';
import {SeatingPlanComponent} from './components/reservation/seat-selection/seating-plan/seating-plan.component';
import {SeatSelectionComponent} from './components/reservation/seat-selection/seat-selection.component';
import {OptionsComponent} from './components/reservation/options/options.component';
import {AddressComponent} from './components/reservation/address/address.component';
import {ContactComponent} from './components/contact/contact.component';
import {CheckComponent} from './components/reservation/check/check.component';
import {ConfirmationComponent} from './components/reservation/confirmation/confirmation.component';
import {AlertModalComponent} from './components/modals/alert-modal.component';
import {AlreadyLockedModalComponent} from './components/modals/already-locked-modal.component';
import {AlreadyTakenModalComponent} from './components/modals/already-taken-modal.component';
import {CancelReservationModalComponent} from './components/modals/cancel-reservation-modal.component';
import {ChangePerformanceModalComponent} from './components/modals/change-performance-modal.component';
import {OutdatedBrowserModalComponent} from './components/modals/outdated-browser-modal.component';
import {PerformanceLockedModalComponent} from './components/modals/performance-locked-modal.component';
import {ResumeReservationModalComponent} from './components/modals/resume-reservation-modal.component';
import {SelectionExpiredModalComponent} from './components/modals/selection-expired-modal.component';
import {ProgressIndicatorComponent} from './components/progress-indicator/progress-indicator.component';
import {AdminComponent} from './components/admin/admin.component';
import {LoginComponent} from './components/admin/login/login.component';
import {ReservationsComponent} from './components/admin/reservations/reservations.component';
import {ReservationDetailsComponent} from './components/admin/reservations/reservation-details/reservation-details.component';
import {AuthService} from './services/auth.service';
import {AuthGuardService} from './services/auth-guard.service';
import {RoleGuardService} from './services/role-guard.service';
import {AuthInterceptor} from './auth-interceptor';

registerLocaleData(localeDeCh);

@NgModule({
  declarations: [
    AppComponent,
    PerformanceSelectionComponent,
    ReservationComponent,
    SeatingPlanComponent,
    PageNotFoundComponent,
    SeatSelectionComponent,
    LandingPageComponent,
    OptionsComponent,
    AddressComponent,
    ContactComponent,
    CheckComponent,
    AlertModalComponent,
    AlreadyLockedModalComponent,
    AlreadyTakenModalComponent,
    CancelReservationModalComponent,
    ChangePerformanceModalComponent,
    OutdatedBrowserModalComponent,
    PerformanceLockedModalComponent,
    SelectionExpiredModalComponent,
    ResumeReservationModalComponent,
    ProgressIndicatorComponent,
    ConfirmationComponent,
    AdminComponent,
    LoginComponent,
    ReservationsComponent,
    ReservationDetailsComponent
  ],
  imports: [
    AlertModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    LuxonModule,
    AppRoutingModule,
    RecaptchaV3Module,
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  providers: [
    AdminService,
    AuthService,
    AuthGuardService,
    BsDatepickerConfig,
    CategoryService,
    MessageService,
    PerformanceService,
    ProductionService,
    ReservationService,
    RoleGuardService,
    SeatLabelService,
    SeatingPlanService,
    {
      provide: LOCALE_ID,
      useValue: 'de-CH'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: '6LfBI5sUAAAAAMmvKi9o1H2G20H2fcqpQOerLlrM'
    }
  ],
  entryComponents: [
    AlertModalComponent,
    AlreadyLockedModalComponent,
    AlreadyTakenModalComponent,
    CancelReservationModalComponent,
    ChangePerformanceModalComponent,
    OutdatedBrowserModalComponent,
    PerformanceLockedModalComponent,
    ResumeReservationModalComponent,
    SelectionExpiredModalComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
