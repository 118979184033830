import { Component } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

@Component({
  selector: "app-resume-reservation-modal",
  templateUrl: "./resume-reservation-modal.component.html",
})
export class ResumeReservationModalComponent {
  public modalService: BsModalService;

  constructor(private modalRef: BsModalRef) {}

  continueReservation(): void {
    this.modalService.setDismissReason("continue");
    this.modalRef.hide();
  }

  newReservation(): void {
    this.modalService.setDismissReason("new");
    this.modalRef.hide();
  }

  cancel(): void {
    this.modalService.setDismissReason("cancel");
    this.modalRef.hide();
  }
}
