<div>
  <div class="modal-header">
    <h3 class="modal-title">Auswahl nicht m&ouml;glich</h3>
  </div>
  <div class="modal-body">
    <p *ngIf="performance">
      F&uuml;r die Auff&uuml;hrung vom
      <b>{{performance.date | dateTimeFromFormat:'yyyy-MM-dd HH:mm:ss' | date:'d. MMMM yyyy | HH:mm \'Uhr\''}}</b>
      werden keine Online-Reservationen mehr entgegengenommen.
    </p>
    <p>
      Bitte w&auml;hlen Sie ein anderes Auff&uuml;hrungsdatum aus.
    </p>
  </div>
  <div class="modal-footer">
    <button class="btn btn-default" (click)="dismiss()">OK</button>
  </div>
</div>
