import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>> {

    const sessionReq = req.clone({
      withCredentials: true
    });

    const jwt = localStorage.getItem('jwt');

    if (jwt) {
      const authReq = sessionReq.clone({
        headers: sessionReq.headers.set('Authorization', 'Bearer ' + jwt)
      });

      return next.handle(authReq);
    } else {
      return next.handle(sessionReq);
    }
  }
}
