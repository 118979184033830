import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-progress-indicator',
  templateUrl: './progress-indicator.component.html',
  styleUrls: ['./progress-indicator.component.scss']
})
export class ProgressIndicatorComponent implements OnInit {

  private _value: number;
  private _max: number;
  numbers: number[];

  constructor() {
    this.updateNumbers();
  }

  ngOnInit() {
  }

  updateNumbers() {
    this.numbers = Array(this._max).fill(0).map((x, i) => i);
  }

  get value(): number {
    return this._value;
  }

  @Input()
  set value(value: number) {
    this._value = value;
    this.updateNumbers();
  }

  get max(): number {
    return this._max;
  }

  @Input()
  set max(max: number) {
    this._max = max;
    this.updateNumbers();
  }
}
