<div>
  <div class="modal-header">
    <h3 class="modal-title">Auswahl abgelaufen</h3>
  </div>
  <div class="modal-body">
    <p>
      Einige der ausgew&auml;hlten Pl&auml;tze wurden wieder freigegeben,
      da sie f&uuml;r mehr als <span class="nobr">{{ttlMinutes}} Minuten</span> ausgew&auml;hlt waren,
      aber keine Reservation erfolgte.
    </p>
    <p>
      Bitte kontrollieren Sie die ausgew&auml;hlten Pl&auml;tze
      und w&auml;hlen Sie diese allenfalls noch einmal aus.
    </p>
  </div>
  <div class="modal-footer">
    <button class="btn btn-default" (click)="dismiss()">OK</button>
  </div>
</div>
