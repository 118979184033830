import { EventEmitter, Injectable, Input, Output, Directive } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {DateTime} from 'luxon';
import {tap} from 'rxjs/operators';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import * as globals from '../globals';
import {MessageService} from './message.service';
import {PerformanceService} from './performance.service';
import {Address} from '../entities/address';
import {Performance} from '../entities/performance';
import {Production} from '../entities/production';
import {Reservation} from '../entities/reservation';
import {SeatingPlan} from '../entities/seatingPlan';
import {RangeSet} from '../entities/rangeSet';
import {SeatingPlanService} from './seating-plan.service';

@Directive()
@Injectable()
export class ReservationService {

  @Output() redrawSeatingPlan: EventEmitter<boolean> = new EventEmitter();
  reservation: Reservation;
  @Input() seatingPlan: SeatingPlan;
  isBooking: boolean;
  public isAdmin: boolean;
  public productionSource = new ReplaySubject<Production>();
  public performanceSource = new ReplaySubject<Performance>();

  constructor(private http: HttpClient,
              private router: Router,
              private performanceService: PerformanceService,
              private seatingPlanService: SeatingPlanService,
              private messageService: MessageService) {
    this.restoreReservation();
    this.evaluateSelectedCategories();
  }

  private static seatSort(a, b) {
    return a - b;
  }

  clearReservation() {
    const production = this.reservation ? this.reservation.production : new Production();
    const reservation = new Reservation();

    reservation.production = production;
    reservation.performance = null;

    reservation.selectedSeats = [];
    reservation.allSelectedSeats = [];
    reservation.categories = [];
    reservation.selectedCategories = 0;
    reservation.total = 0;

    reservation.address = this.clearAddress();
    reservation.editingToken = null;

    this.reservation = reservation;
  }

  clearAddress() {
    const address = new Address();

    address.id = null;

    address.firstname = null;
    address.lastname = null;

    address.address = null;
    address.zip = null;
    address.city = null;

    address.email = null;
    address.phone = null;

    address.seats = [];
    address.options = [];
    address.announceNextProduction = false;
    address.notes = null;

    address.showDetails = false;
    address.details = null;

    return address;
  }

  // Reset all reservation data.
  resetReservation() {
    this.unlockAllSeats();
    this.clearReservation();
    this.seatingPlanService.seatingPlan = undefined;
    // this.productionService.resetSeatingPlan();

    const categories = this.reservation.categories.map(x => Object.assign({}, x)); // clone array

    categories.forEach((category) => {
      category.value = 0;
    });

    this.reservation.selectedCategories = 0;
    this.reservation.categories = categories;
    this.reservation.editingToken = null;

    this.evaluateSelectedCategories();
    this.saveReservation();
  }

  reset(force: boolean, seatsLinkRoute: string[]) {
    if (force) {
      // Reset current seating plan and a start new one.
      this.resetReservation();
      this.router.navigate(seatsLinkRoute);
    } else if (this.reservation.selectedSeats.length > 0 || this.reservation.performance !== null) {
      this.messageService.resumeReservation().subscribe((reason) => {
        if (reason === 'new') {
          // Start a new seating plan.
          this.resetReservation();
        }

        if (reason !== 'cancel') {
          // Continue current seating plan.
          this.router.navigate(seatsLinkRoute);
        }
      });
    } else {
      this.router.navigate(seatsLinkRoute);
    }
  }

  applyUpdates(updates) {
    this.seatingPlan.seats.forEach((seat) => {
      seat.l = false;
    });

    updates.l.forEach((lockedSeatId) => {
      this.seatingPlan.seats.forEach((seat) => {
        if (seat.id === lockedSeatId) {
          seat.l = true;
        }
      });
    });

    this.redrawSeatingPlan.emit(true);
  }

  // Update local array of selected seats.
  updateSelectedSeats(seatIds: number[], showExpiredMessage: boolean) {
    const existingSelection = (this.reservation.selectedSeats.length > 0);
    const equal = (this.reservation.selectedSeats.sort(ReservationService.seatSort).join(',') ===
      seatIds.sort(ReservationService.seatSort).join(','));

    if (!equal) {
      // Fix selected seats.
      Array.prototype.splice.apply(this.reservation.selectedSeats, [0, this.reservation.selectedSeats.length].concat(seatIds));

      this.saveReservation();

      // Deselect any seats on the seating plan that have been released due to the timeout.
      if (this.seatingPlan && this.seatingPlan.seats) {
        this.seatingPlan.seats.forEach((seat) => {
          seat.s = this.reservation.selectedSeats.indexOf(seat.id) !== -1;
        });
      }

      // Update all seats.
      this.performanceSource.next(this.reservation.performance);

      if (existingSelection && showExpiredMessage) {
        this.messageService.alertExpiredSelection();
      }
    }
  }

  lockSeat(seatId: number, adminToken: string) {
    // Add selected seat id to selected seats array.
    if (this.reservation.selectedSeats.indexOf(seatId) <= 0) {
      this.reservation.selectedSeats.push(seatId);
      this.reservation.selectedSeats.sort(ReservationService.seatSort);
    }

    this.http.get<any>(globals.apiUrl + 'lock/' + this.reservation.performance.id + '/' + seatId + '/' + adminToken)
      .subscribe((data) => {
        this.updateSelectedSeats(data.u.m, data.r);
        this.applyUpdates(data.u);

        if (data.r) {
          this.updateAuthenticationToken(data.jwt, data.jwt_exp);
        } else {
          // Error: Already reserved.
          this.seatingPlan.l = data.u.l;
          this.seatingPlan.t = data.u.t;

          if (data.pl) {
            // Performance is locked
            const performance: Performance = Object.assign({}, this.reservation.performance);
            this.messageService.alertPerformanceLocked(performance);
            this.clearReservation();
            this.saveReservation();
          }

          this.seatingPlan.seats.forEach((seat) => {
            if (seat.id === seatId) {
              seat.s = false;
              seat.l = data.u.l.indexOf(seatId) !== -1;
              seat.t = data.u.t.indexOf(seatId) !== -1;

              if (seat.t) {
                this.messageService.alertAlreadyTaken();
              } else if (seat.l) {
                this.messageService.alertAlreadyLocked();
              }
            }
          });

          // Remove selected seat from selected seats array.
          const index = this.reservation.selectedSeats.indexOf(seatId);
          if (index > -1) {
            this.reservation.selectedSeats.splice(index, 1);
          }

          // Remove all taken and locked seats from current selection.
          data.u.l.forEach(lockedSeatId => {
            const lockedSeatIndex = this.reservation.selectedSeats.indexOf(lockedSeatId);
            if (lockedSeatIndex !== -1) {
              this.reservation.selectedSeats.splice(lockedSeatIndex, 1);
            }
          });
          data.u.t.forEach(takenSeatId => {
            const takenSeatIndex = this.reservation.selectedSeats.indexOf(takenSeatId);
            if (takenSeatIndex !== -1) {
              this.reservation.selectedSeats.splice(takenSeatIndex, 1);
            }
          });

          this.redrawSeatingPlan.emit(true);
        }

        this.evaluateSelectedCategories();
        this.saveReservation();
      });
  }

  unlockSeat(seatId: number, adminToken: string) {
    // Remove selected seat id from selected seats array.
    const seatIndex = this.reservation.selectedSeats.indexOf(seatId);
    if (seatIndex !== -1) {
      this.reservation.selectedSeats.splice(seatIndex, 1);
      this.saveReservation();
    }

    // Reduce seat-dependent category.
    const seatCategory = this.getSeatCategory(seatId);
    seatCategory.value -= 1;

    this.http.get<any>(globals.apiUrl + 'unlock/' + this.reservation.performance.id + '/' + seatId + '/' + adminToken)
      .subscribe((data) => {
        this.updateSelectedSeats(data.u.m, true);
        this.evaluateSelectedCategories();
        this.applyUpdates(data.u);

        if (data.r) {
          // OK
        } else {
          // Seat could not be unlocked.
          // It might already have been reserved in the meantime (-> data.a)
          // or it was not locked in the first place.
          /*
          if (!data.a && this.isAdmin) {
            // Error
            this.messageService.alert('Fehler',
              'Der ausgewählte Sitz konnte nicht freigegeben werden. ' +
              'Wenn das Problem weiterhin besteht, starten Sie die Reservation bitte von vorne.');
          }
          */
        }
      });
  }

  unlockAllSeats() {
    this.http.get<any>(globals.apiUrl + 'unlock/all')
      .subscribe((/* data */) => {
        if (this.seatingPlan && this.seatingPlan.seats) {
          // Unselect any selected seats on the seating plan.
          this.seatingPlan.seats.forEach((seat) => seat.s = false);
        }
        this.redrawSeatingPlan.emit(true);
      });
  }

  updateAuthenticationToken(token, expirationDate) {
    // Save updated token.
    const expiresAt = DateTime.fromMillis(expirationDate * 1000);

    localStorage.setItem('jwt', token);
    localStorage.setItem('jwt_exp', JSON.stringify(expiresAt.valueOf()));
  }

  getSeatCategory(seatId: number) {
    if (this.reservation.categories) {
      for (let categoryIndex = 0; categoryIndex < this.reservation.categories.length; categoryIndex++) {
        const category = this.reservation.categories[categoryIndex];
        if (category.seatRanges) {
          const seatRanges = category.seatRanges;
          for (let seatRangesIndex = 0; seatRangesIndex < seatRanges.length; seatRangesIndex++) {
            if (seatId >= seatRanges[seatRangesIndex].fromSeatId && seatId <= seatRanges[seatRangesIndex].toSeatId) {
              return category;
            }
          }
        }
      }
    }

    return null;
  }

  chooseCategory(category, rangeSet: RangeSet, value) {
    category.value = value;

    this.adaptSelectedCategories(category, rangeSet);
    this.saveReservation();
    this.evaluateSelectedCategories();
  }

  // Adapt other categories in the same range set so that the amount
  // of selected seat categories matches the number of selected seats.
  adaptSelectedCategories(category, rangeSet: RangeSet) {
    const maxSelectedSeatsInRangeSet = rangeSet.selectedSeatsCount;
    const currentSelectedSeatsInRangeSet = rangeSet.categories.reduce((total, item) => {
      return total + item.value;
    }, 0);
    let correction = maxSelectedSeatsInRangeSet - currentSelectedSeatsInRangeSet;
    rangeSet.categories.forEach((cat) => {
      if (cat !== category) {
        while (correction !== 0) {
          if (correction > 0) {
            cat.value += 1;
            correction -= 1;
          } else if (correction < 0) {
            if (cat.value > 0) {
              cat.value -= 1;
              correction += 1;
            } else {
              break;
            }
          }
        }
      }
    });
  }

  evaluateSelectedCategories() {
    let total = 0; // total price for all seats
    let selectedCategories = 0; // number of seats for which a category has been selected

    if (this.reservation.categories) {
      this.reservation.categories.forEach((category) => {
        selectedCategories += category.value;
        total += category.value * category.price;
      });
    }

    this.reservation.selectedCategories = selectedCategories;
    this.reservation.total = total;
  }

  // Finish reservation.
  book(confirmationRoute: string[]) {
    const categories = {};
    const options = [];

    if (!this.isBooking) {
      this.reservation.categories.forEach((category) => {
        categories[category.id] = category.value;
      });

      const data = {
        p: this.reservation.performance.id,
        s: this.reservation.selectedSeats,
        a: this.reservation.address,
        c: categories,
        o: options,
        t: this.reservation.editingToken
      };

      this.isBooking = true;

      return this.http.post<any>(globals.apiUrl + 'book', data)
        .pipe(
          tap((result) => {
            this.isBooking = false;

            if (result.r) {
              this.resetReservation();
              // Reset seating plan
              this.seatingPlanService.seatingPlan = undefined;
              this.router.navigate(confirmationRoute);
            } else {
              this.messageService.alert('Fehler',
                'Es ist ein Fehler bei der Reservation aufgetreten. ' +
                'Die Reservation konnte nicht abgeschlossen werden. ' +
                'Bitte wiederholen Sie die Reservation. ' +
                'Sollte der Fehler weiterhin auftreten, nehmen Sie bitte mit uns Kontakt auf.');

              // Error: Already reserved
              result.e.forEach((errorSeatId) => {
                this.seatingPlan.seats.forEach((seat) => {
                  if (seat.id === errorSeatId) {
                    seat.s = false;
                    seat.t = true;
                  }
                });

                // Remove selected seat ID from selected seats array.
                const seatIndex = this.reservation.selectedSeats.indexOf(errorSeatId);
                if (seatIndex > -1) {
                  this.reservation.selectedSeats.splice(seatIndex, 1);
                  this.saveReservation();
                }
              });

              // this.location.go('/reservation/seats');
            }
          })
        );
    }
  }

  restoreReservation() {
    const reservationData = localStorage.getItem('reservation');

    if (reservationData) {
      this.reservation = JSON.parse(reservationData);
    } else {
      this.clearReservation();
    }
  }

  saveReservation() {
    localStorage.setItem('reservation', JSON.stringify(this.reservation));
  }

  selectProduction(production: Production) {
    production.disclaimerHTML = this.parseDisclaimer(production.disclaimer);
    this.reservation.production = production;
    this.productionSource.next(production);
  }

  parseDisclaimer(disclaimer: string) {
    return '<p>' + disclaimer
      .replace(/[\u00A0-\u9999<>&](?!#)/gim, match => '&#' + match.charCodeAt(0) + ';')
      .replace(/\r\n\r\n/gim, () => '</p><p>')
      .replace(/\[(.*)]\((.*)\)\s/gim, (match, p1, p2) => '<a href="' + p1 + '" target="_blank">' + p2 + '</a> ') + '</p>';
  }

  selectPerformance(performance: Performance) {
    if (!this.reservation.performance || (performance && this.reservation.performance.id !== performance.id)) {
      this.reservation.performance = performance;
      this.performanceSource.next(performance);
    }
  }
}
