export class Contact {
  productionId: string;

  firstname: string;
  lastname: string;

  email: string;
  phone: string;

  message: string;
}
