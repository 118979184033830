import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import * as _ from 'underscore';
import {AdminService} from '../../../services/admin.service';
import {CategoryService} from '../../../services/category.service';
import {ReservationService} from '../../../services/reservation.service';
import {SeatingPlanService} from '../../../services/seating-plan.service';
import {SeatLabelService} from '../../../services/seat-label.service';
import {SeatRange} from '../../../entities/seatRange';
import {RangeSet} from '../../../entities/rangeSet';

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.scss']
})
export class OptionsComponent implements OnInit, OnDestroy {

  baseRouteSubscription: Subscription;
  public seatsLinkRoute: string[];
  public addressLinkRoute: string[];
  public seatRanges: SeatRange[];
  public selectedRangeSets: RangeSet[];

  constructor(private router: Router,
              public reservationService: ReservationService,
              private seatLabelService: SeatLabelService,
              private seatingPlanService: SeatingPlanService,
              private categoryService: CategoryService,
              private adminService: AdminService,
              private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.baseRouteSubscription = this.adminService.routeBaseChanged.subscribe(routeBase => {
      this.seatsLinkRoute = routeBase.concat('seats');
      this.addressLinkRoute = routeBase.concat('address');
    });
    this.seatsLinkRoute = this.adminService.getRoute('seats');
    this.addressLinkRoute = this.adminService.getRoute('address');

    this.selectedRangeSets = [];

    this.categoryService.loadCategories(this.reservationService.reservation.performance).subscribe((result) => {
      this.seatRanges = this.reservationService.reservation.seatRanges;

      // Find all relevant ranges
      const selectedRangeSets = [];
      for (let selectedSeatIndex = 0; selectedSeatIndex < this.reservationService.reservation.selectedSeats.length; selectedSeatIndex++) {
        const seatId = this.reservationService.reservation.selectedSeats[selectedSeatIndex];
        const seatRange = _.find(this.seatRanges, function (categoryBySeatRange: SeatRange) {
          return (categoryBySeatRange.fromSeatId <= seatId && categoryBySeatRange.toSeatId >= seatId);
        });

        const rangeSet = _.find(this.reservationService.reservation.categoriesByRangeSet, function (seatRangeByRangeSet) {
          return seatRangeByRangeSet.ranges.indexOf(seatRange) !== -1;
        });

        if (rangeSet) {
          const existingRangeSetIndex = selectedRangeSets.indexOf(rangeSet);
          if (existingRangeSetIndex === -1) {
            selectedRangeSets.push(rangeSet);
          }
        }
      }

      this.selectedRangeSets = selectedRangeSets;

      this.reservationService.evaluateSelectedCategories();

      this.changeDetectorRef.markForCheck();
    });

    this.scrollToTop();
  }

  scrollToTop() {
    const pageTitleElement = document.getElementById('pageTitle');
    pageTitleElement.scrollIntoView();
  }

  navigate(route) {
    this.router.navigate(route);
  }

  getSeatLabel(seatId) {
    if (this.seatingPlanService.seatingPlan) {
      SeatLabelService.getSeatLabel(this.seatingPlanService.seatingPlan, this.seatingPlanService.exceptions, seatId);
    }
  }

  getNumber(number: number) {
    return new Array(number);
  }

  ngOnDestroy() {
    if (this.baseRouteSubscription) {
      this.baseRouteSubscription.unsubscribe();
    }
  }
}
