<h5>Anmelden</h5>
<form>
  <div class="input-group mb-2">
    <input type="text"
           id="loginInput"
           name="loginInput"
           #loginInput="ngModel"
           class="form-control"
           placeholder="Login"
           autocomplete="login"
           [(ngModel)]="login"
           autofocus="autofocus"
           required/>
  </div>
  <div class="input-group mb-2">
    <input type="password"
           id="passwordInput"
           name="passwordInput"
           #passwordInput="ngModel"
           class="form-control"
           placeholder="Passwort"
           autocomplete="password"
           [(ngModel)]="password"
           required/>
  </div>
  <div class="p-2 mb-2 bg-danger text-white rounded-box" *ngIf="loginError">
    Anmeldung fehlgeschlagen. Der Benutzername oder das Passwort ist falsch.
  </div>
  <div class="input-group">
    <button type="submit"
            class="btn btn-primary"
            (click)="authService.login(login, password)"
            [disabled]="loginInput.errors?.required || passwordInput.errors?.required">
      Anmelden
    </button>
  </div>
</form>
