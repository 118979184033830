import { Injectable } from "@angular/core";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import * as globals from "../globals";
import { AlertModalComponent } from "../components/modals/alert-modal.component";
import { AlreadyLockedModalComponent } from "../components/modals/already-locked-modal.component";
import { AlreadyTakenModalComponent } from "../components/modals/already-taken-modal.component";
import { CancelReservationModalComponent } from "../components/modals/cancel-reservation-modal.component";
import { ChangePerformanceModalComponent } from "../components/modals/change-performance-modal.component";
import { PerformanceLockedModalComponent } from "../components/modals/performance-locked-modal.component";
import { ResumeReservationModalComponent } from "../components/modals/resume-reservation-modal.component";
import { SelectionExpiredModalComponent } from "../components/modals/selection-expired-modal.component";
import { Performance } from "../entities/performance";
import { OutdatedBrowserModalComponent } from "../components/modals/outdated-browser-modal.component";

@Injectable()
export class MessageService {
  public modalRef: BsModalRef;
  private defaultConf: ModalOptions = {
    animated: true,
    keyboard: false,
    backdrop: "static",
    ignoreBackdropClick: true,
  };

  constructor(private modalService: BsModalService) {}

  alert(title: string, message: string) {
    this.modalRef = this.modalService.show(
      AlertModalComponent,
      this.defaultConf
    );
    this.modalRef.content.modalService = this.modalService;
    this.modalRef.content.title = title;
    this.modalRef.content.message = message;

    return this.modalService.onHide;
  }

  resumeReservation() {
    this.modalRef = this.modalService.show(
      ResumeReservationModalComponent,
      this.defaultConf
    );
    this.modalRef.content.modalService = this.modalService;

    return this.modalService.onHide;
  }

  alertAlreadyLocked() {
    this.modalRef = this.modalService.show(AlreadyLockedModalComponent);
    this.modalRef.content.modalService = this.modalService;

    return this.modalService.onHide;
  }

  alertAlreadyTaken() {
    this.modalRef = this.modalService.show(AlreadyTakenModalComponent);
    this.modalRef.content.modalService = this.modalService;

    return this.modalService.onHide;
  }

  alertExpiredSelection() {
    this.modalRef = this.modalService.show(SelectionExpiredModalComponent);
    this.modalRef.content.modalService = this.modalService;
    this.modalRef.content.ttlMinutes = globals.lockTTL / 60;

    return this.modalService.onHide;
  }

  alertCancelReservation() {
    this.modalRef = this.modalService.show(
      CancelReservationModalComponent,
      this.defaultConf
    );
    this.modalRef.content.modalService = this.modalService;

    return this.modalService.onHide;
  }

  alertChangePerformance(performance: Performance) {
    this.modalRef = this.modalService.show(
      ChangePerformanceModalComponent,
      this.defaultConf
    );
    this.modalRef.content.modalService = this.modalService;
    this.modalRef.content.performance = performance;

    return this.modalService.onHide;
  }

  alertOutdatedBrowser() {
    this.modalRef = this.modalService.show(
      OutdatedBrowserModalComponent,
      this.defaultConf
    );
    this.modalRef.content.modalService = this.modalService;

    return this.modalService.onHide;
  }

  alertPerformanceLocked(performance: Performance) {
    this.modalRef = this.modalService.show(
      PerformanceLockedModalComponent,
      this.defaultConf
    );
    this.modalRef.content.modalService = this.modalService;
    this.modalRef.content.performance = performance;

    return this.modalService.onHide;
  }
}
