<div>
  <div class="modal-header">
    <h3 class="modal-title">Reservation noch nicht abgeschlossen</h3>
  </div>
  <div class="modal-body">nav-right
    <p>Wir haben festgestellt, dass Sie Ihre letzte Reservation noch nicht abgeschlossen haben.</p>
    <p>M&ouml;chten Sie Ihre aktuelle Reservation forsetzen oder eine neue Reservation t&auml;tigen?</p>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" (click)="continueReservation()">Fortfahren</button>
    <button class="btn btn-secondary" (click)="newReservation()">Neue Reservation</button>
    <button class="btn btn-default" (click)="cancel()">Abbrechen</button>
  </div>
</div>
