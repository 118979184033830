import {Production} from './production';

export class Admin {
  query: string;
  production: Production;
  reservationDate: Date;
  performanceDate: Date;
  listResult: any[];
  searchResult: any[];
}
