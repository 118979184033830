import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import * as globals from '../globals';
import {Contact} from '../entities/contact';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) {
  }

  // Send contact form.
  contact(contact: Contact) {
    return this.http.post<any>(globals.apiUrl + 'contact', contact);
  }
}
