import { Component } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

@Component({
  selector: "app-performance-taken-modal",
  templateUrl: "./performance-locked-modal.component.html",
  providers: [BsModalService],
})
export class PerformanceLockedModalComponent {
  public performance: Performance;
  public modalService: BsModalService;

  constructor(private modalRef: BsModalRef) {}

  dismiss(): void {
    this.modalService.setDismissReason("cancel");
    this.modalRef.hide();

    // Reload page to refresh list of performances.
    location.reload();
  }
}
