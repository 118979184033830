import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot} from '@angular/router';
import {AuthService} from './auth.service';
import jwt_decode from 'jwt-decode';
import {AdminService} from './admin.service';

@Injectable()
export class RoleGuardService implements CanActivate {
  constructor(private authService: AuthService, private adminService: AdminService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    // This will be passed from the route config on the data property.
    const expectedRole = route.data.expectedRole;
    const jwt = localStorage.getItem('jwt');

    // Decode the token to get its payload.
    const tokenPayload: any = jwt_decode(jwt);
    if (!this.authService.isAuthenticated || tokenPayload.user.role !== expectedRole) {
      this.router.navigate(this.adminService.getRoute('admin', 'login'));
      return false;
    }
    return true;
  }
}
