import {AfterContentInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {forkJoin} from 'rxjs';
import {Subscription} from 'rxjs/Subscription';
import * as d3 from 'd3';
import {AdminService} from '../../../../services/admin.service';
import {CategoryService} from '../../../../services/category.service';
import {MessageService} from '../../../../services/message.service';
import {ReservationService} from '../../../../services/reservation.service';
import {SeatingPlanService} from '../../../../services/seating-plan.service';
import {SeatLabelService} from '../../../../services/seat-label.service';
import {Performance} from '../../../../entities/performance';

@Component({
  selector: 'app-seating-plan',
  templateUrl: './seating-plan.component.html',
  styleUrls: ['./seating-plan.component.scss'],
  encapsulation: ViewEncapsulation.None // allows styling of elements deeper in the component
})
export class SeatingPlanComponent implements OnInit, OnDestroy, AfterContentInit {
  public isLoadingSeatingPlan = false;
  private currentPerformanceId: number;
  performanceSubscription: Subscription;
  redrawSeatingPlanSubscription: Subscription;

  // D3 DOM elements
  seatingPlanDiv: any;
  stageLabelDiv: any;
  stageSize = 30;

  constructor(
    public seatingPlanService: SeatingPlanService,
    public reservationService: ReservationService,
    private adminService: AdminService,
    private seatLabelService: SeatLabelService,
    private categoryService: CategoryService,
    private messageService: MessageService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.reservationService.reservation.performance) {
      this.getSeatingPlan(this.reservationService.reservation.performance);
    }

    this.performanceSubscription = this.reservationService.performanceSource.subscribe(performance => {
      if (performance) {
        this.currentPerformanceId = performance.id;
        this.getSeatingPlan(performance);
      }
    });

    this.redrawSeatingPlanSubscription = this.reservationService.redrawSeatingPlan.subscribe(() => {
      this.updateSeatingPlan();
      this.changeDetectorRef.markForCheck();
    });
  }

  ngAfterContentInit() {}

  drawLoadingSeatingPlan() {
    this.seatingPlanDiv = d3.select('#seatingPlan');

    if (!this.seatingPlanDiv) {
      return;
    }

    // Create loading animation.
    const loading = this.seatingPlanDiv.append('g').attr('id', 'loading');

    // Create loading message.
    loading
      .append('text')
      .attr('id', 'waitMessage')
      .attr('x', (this.seatingPlanService.planWidth || 600) / 2)
      .attr('y', 20)
      .attr('text-anchor', 'middle')
      .text('Sitzplan wird geladen...');

    // Create circles for loading animation.
    this.createCircle.bind(this)(loading, 1, 5);
    this.createCircle.bind(this)(loading, 2, 5);
    this.createCircle.bind(this)(loading, 3, 5);
    this.createCircle.bind(this)(loading, 4, 5);
    this.createCircle.bind(this)(loading, 5, 5);
  }

  drawSeatingPlan() {
    this.seatingPlanDiv = d3.select('#seatingPlan');

    if (!this.seatingPlanService.seatingPlan) {
      setTimeout(this.drawSeatingPlan, 0);
    }

    // Create SVG root element.
    this.seatingPlanDiv.attr('width', this.seatingPlanService.planWidth).attr('height', this.seatingPlanService.planHeight);

    // Create background.
    this.seatingPlanDiv
      .append('rect')
      .attr('width', this.seatingPlanService.planWidth)
      .attr('height', this.seatingPlanService.planHeight)
      .attr('fill', '#EEEEEE');

    // Create stage rectangle.
    this.seatingPlanDiv.append('rect').attr('width', this.seatingPlanService.planWidth).attr('height', this.stageSize).attr('class', 'stage');

    // Create stage label.
    this.stageLabelDiv = this.seatingPlanDiv
      .append('text')
      .attr('x', this.seatingPlanService.planWidth / 2)
      .attr('y', 20)
      .attr('class', 'stage-text')
      .attr('text-anchor', 'middle');

    this.stageLabelDiv.text(this.reservationService.seatingPlan.stageLabel);
  }

  updateSeatingPlan() {
    if (!this.seatingPlanDiv) {
      return;
    }

    if (!this.reservationService.seatingPlan || !this.seatingPlanService.seatingPlan) {
      // Load seating plan (again) if necessary
      if (this.reservationService.reservation.performance) {
        this.seatingPlanService.getSeatingPlan(this.reservationService.reservation.performance).subscribe(() => this.updateSeatingPlan());
      }
      // setTimeout(this.updateSeatingPlan, 0);
      return;
    }

    // Remove all seats.
    this.seatingPlanDiv.selectAll('.seat').remove();

    // Create tables.
    if (this.seatingPlanService.hasTables) {
      this.seatingPlanDiv.selectAll('.table').remove();
      const tableElement = this.seatingPlanDiv.selectAll('.table').data(this.tables()).enter().append('g').attr('class', 'table');

      tableElement
        .append('rect')
        .attr('transform', d => {
          let dX = 0;
          let dY = 0;

          if (this.seatingPlanService.rotatePlan) {
            dX =
              (d.r * 2 - 1) * this.seatingPlanService.seatWidth +
              (d.r - 1) * (this.seatingPlanService.tableWidth + this.seatingPlanService.hPadding) +
              this.seatingPlanService.hOffset;
            dY = (d.t - 1) * (this.seatingPlanService.tableHeight + this.seatingPlanService.vPadding) + this.seatingPlanService.vOffset;

            dX += SeatLabelService.summedRowGap(this.seatingPlanService.seatingPlan, this.seatingPlanService.planWidth, 2 * d.r - 1);
            dY += SeatLabelService.summedColumnGap(
              this.seatingPlanService.seatingPlan,
              this.seatingPlanService.planHeight,
              (d.t * this.reservationService.seatingPlan.seatsPerTable) / 2 - 1
            );
          } else {
            dX = (d.t - 1) * (this.seatingPlanService.tableWidth + this.seatingPlanService.hPadding) + this.seatingPlanService.hOffset;
            dY =
              (d.r * 2 - 1) * this.seatingPlanService.seatHeight +
              (d.r - 1) * (this.seatingPlanService.tableHeight + this.seatingPlanService.vPadding) +
              this.seatingPlanService.vOffset;

            dX += SeatLabelService.summedColumnGap(
              this.seatingPlanService.seatingPlan,
              this.seatingPlanService.planWidth,
              (d.t * this.reservationService.seatingPlan.seatsPerTable) / 2 - 1
            );
            dY += SeatLabelService.summedRowGap(this.seatingPlanService.seatingPlan, this.seatingPlanService.planHeight, 2 * d.r - 1);
          }
          return 'translate(' + dX + ',' + dY + ')';
        })
        .attr('height', this.seatingPlanService.tableHeight)
        .attr('width', this.seatingPlanService.tableWidth)
        .attr('class', d => {
          const hiddenOrSkipped = d.seatIds.reduce((hideOrSkip, rawSeatId) => {
            const skipped = SeatLabelService.isSeatSkipped(this.seatingPlanService.seatingPlan, rawSeatId);
            const hidden = SeatLabelService.isSeatHidden(this.seatingPlanService.seatingPlan, rawSeatId);
            return hideOrSkip && (skipped || hidden);
          }, true);
          return (hiddenOrSkipped ? 'hidden' : '') + ' start-seat-id-' + d.seatIds[0];
        });
    }

    // Create seats.
    const seatElement = this.seatingPlanDiv
      .selectAll('.seat')
      .data(this.reservationService.seatingPlan.seats)
      .enter()
      .append('g')
      .attr('transform', d => {
        let dX = 0;
        let dY = 0;

        if (this.seatingPlanService.rotatePlan) {
          if (this.seatingPlanService.hasTables) {
            dX =
              Math.floor(d.r / 2) * this.seatingPlanService.tableWidth +
              Math.floor((d.r - 1) / 2) * this.seatingPlanService.hPadding +
              (d.r - 1) * this.seatingPlanService.seatWidth +
              this.seatingPlanService.hOffset;
          } else {
            dX = (d.r - 1) * (this.seatingPlanService.seatWidth + this.seatingPlanService.hPadding) + this.seatingPlanService.hOffset;
          }

          dY = (d.c - 1) * (this.seatingPlanService.seatHeight + this.seatingPlanService.vPadding) + this.seatingPlanService.vOffset;

          dX += SeatLabelService.summedRowGap(this.seatingPlanService.seatingPlan, this.seatingPlanService.planWidth, d.r - 1);
          dY += SeatLabelService.summedColumnGap(this.seatingPlanService.seatingPlan, this.seatingPlanService.planHeight, d.c - 1);
        } else {
          dX = (d.c - 1) * (this.seatingPlanService.seatWidth + this.seatingPlanService.hPadding) + this.seatingPlanService.hOffset;

          if (this.seatingPlanService.hasTables) {
            dY =
              Math.floor(d.r / 2) * this.seatingPlanService.tableHeight +
              Math.floor((d.r - 1) / 2) * this.seatingPlanService.vPadding +
              (d.r - 1) * this.seatingPlanService.seatHeight +
              this.seatingPlanService.vOffset;
          } else {
            dY = (d.r - 1) * (this.seatingPlanService.seatHeight + this.seatingPlanService.vPadding) + this.seatingPlanService.vOffset;
          }

          dX += SeatLabelService.summedColumnGap(this.seatingPlanService.seatingPlan, this.seatingPlanService.planWidth, d.c - 1);
          dY += SeatLabelService.summedRowGap(this.seatingPlanService.seatingPlan, this.seatingPlanService.planHeight, d.r - 1);
        }

        return 'translate(' + dX + ',' + dY + ')';
      })
      .attr('class', 'seat');

    seatElement
      .append('rect')
      .attr('id', d => {
        return (
          'seat-' +
          SeatLabelService.getRealSeatId(
            this.seatingPlanService.seatingPlan,
            d.r,
            d.c,
            this.reservationService.seatingPlan.labelingOrder,
            this.seatingPlanService.exceptions
          )
        );
      })
      .attr('height', this.seatingPlanService.seatHeight)
      .attr('width', this.seatingPlanService.seatWidth)
      .attr('class', d => {
        const rawSeatId = SeatLabelService.getRawSeatId(
          this.seatingPlanService.seatingPlan,
          d.r,
          d.c,
          this.reservationService.seatingPlan.labelingOrder
        );
        const realSeatId = SeatLabelService.getRealSeatId(
          this.seatingPlanService.seatingPlan,
          d.r,
          d.c,
          this.reservationService.seatingPlan.labelingOrder,
          this.seatingPlanService.exceptions
        );
        const taken = this.reservationService.seatingPlan.t.indexOf(realSeatId) !== -1;
        const locked = this.reservationService.seatingPlan.l.indexOf(realSeatId) !== -1;
        const reservedEditing = this.reservationService.reservation.allSelectedSeats.indexOf(realSeatId) !== -1;
        const category = this.reservationService.getSeatCategory(realSeatId);
        const skipped = SeatLabelService.isSeatSkipped(this.seatingPlanService.seatingPlan, rawSeatId);
        const hidden = SeatLabelService.isSeatHidden(this.seatingPlanService.seatingPlan, rawSeatId);
        const selected = this.reservationService.reservation.selectedSeats.indexOf(realSeatId) !== -1;
        return (
          'seat-background' +
          ' raw-id-' +
          rawSeatId +
          ' real-id-' +
          realSeatId +
          (category && category.fillStyle ? ' fill-style-' + category.fillStyle : '') +
          (taken ? ' reserved' : '') +
          (locked ? ' locked' : '') +
          (skipped || hidden ? ' hidden' : '') +
          (selected ? ' selected' : '') +
          (reservedEditing ? ' reserved-editing' : '')
        );
      })
      .on('mouseover', (d, i: number, nodes) => {
        const realSeatId = SeatLabelService.getRealSeatId(
          this.seatingPlanService.seatingPlan,
          d.r,
          d.c,
          this.reservationService.seatingPlan.labelingOrder,
          this.seatingPlanService.exceptions
        );
        const taken = this.reservationService.seatingPlan.t.indexOf(realSeatId) !== -1;
        if (!taken) {
          const g = d3.select(nodes[i]);
          g.classed('hovered', true);
          g.select('text').transition().duration(200).style('opacity', 1);
        }
      })
      .on('mouseout', (d, i: number, nodes) => {
        const g = d3.select(nodes[i]);
        g.classed('hovered', false);
        g.select('text').transition().duration(200).style('opacity', 0);
      })
      .on('click', (d, i: number, nodes) => {
        const realSeatId = SeatLabelService.getRealSeatId(
          this.seatingPlanService.seatingPlan,
          d.r,
          d.c,
          this.reservationService.seatingPlan.labelingOrder,
          this.seatingPlanService.exceptions
        );
        const reservedEditing = this.reservationService.reservation.allSelectedSeats.indexOf(realSeatId) !== -1;
        const taken = this.reservationService.seatingPlan.t.indexOf(realSeatId) !== -1 && !reservedEditing;
        const locked = this.reservationService.seatingPlan.l.indexOf(realSeatId) !== -1;
        const myLocked = this.reservationService.seatingPlan.m.indexOf(realSeatId) !== -1;

        if ((!taken && !locked) || myLocked) {
          d.s = !d.s;
          const g = d3.select(nodes[i]);
          g.select('rect').classed('selected', d.s);

          if (d.s) {
            this.reservationService.lockSeat(realSeatId, this.adminService.adminToken);
          } else {
            this.reservationService.unlockSeat(realSeatId, this.adminService.adminToken);
          }
        } else {
          if (taken) {
            this.messageService.alertAlreadyTaken();
          } else if (locked) {
            this.messageService.alertAlreadyLocked();
          }
        }
      })
      .append('title')
      .text(d => {
        const id = SeatLabelService.getRealSeatId(
          this.seatingPlanService.seatingPlan,
          d.r,
          d.c,
          this.reservationService.seatingPlan.labelingOrder,
          this.seatingPlanService.exceptions
        );
        return SeatLabelService.getSeatLabel(this.seatingPlanService.seatingPlan, this.seatingPlanService.exceptions, id);
      });

    // Create fixed elements.
    const fixedElements = this.fixedElements();
    this.seatingPlanDiv.selectAll('.static').remove();
    this.seatingPlanDiv
      .selectAll('rect.static')
      .data(fixedElements.rects)
      .enter()
      .append('rect')
      .attr('x', d => (this.seatingPlanService.planWidth * d.x) / 100)
      .attr('y', d => ((this.seatingPlanService.planHeight - this.stageSize) * d.y) / 100 + this.stageSize)
      .attr('width', d => (this.seatingPlanService.planWidth * d.width) / 100)
      .attr('height', d => ((this.seatingPlanService.planHeight - this.stageSize) * d.height) / 100)
      .attr('class', 'static');
    this.seatingPlanDiv
      .selectAll('line.static')
      .data(fixedElements.lines)
      .enter()
      .append('line')
      .attr('x1', d => (this.seatingPlanService.planWidth * d.x1) / 100)
      .attr('y1', d => ((this.seatingPlanService.planHeight - this.stageSize) * d.y1) / 100 + this.stageSize)
      .attr('x2', d => (this.seatingPlanService.planWidth * d.x2) / 100)
      .attr('y2', d => ((this.seatingPlanService.planHeight - this.stageSize) * d.y2) / 100 + this.stageSize)
      .attr('class', 'static');
    this.seatingPlanDiv
      .selectAll('circle.static')
      .data(fixedElements.circles)
      .enter()
      .append('circle')
      .attr('cx', d => (this.seatingPlanService.planWidth * d.cx) / 100)
      .attr('cy', d => ((this.seatingPlanService.planHeight - this.stageSize) * d.cy) / 100 + this.stageSize)
      .attr('r', d => (this.seatingPlanService.planWidth * d.r) / 100)
      .attr('class', 'static');
    this.seatingPlanDiv
      .selectAll('text.static')
      .data(fixedElements.texts)
      .enter()
      .append('text')
      .attr('x', d => (this.seatingPlanService.planWidth * d.x) / 100)
      .attr('y', d => ((this.seatingPlanService.planHeight - this.stageSize) * d.y) / 100 + this.stageSize)
      .attr('font-size', d => d.size)
      .attr('class', 'static')
      .text(d => d.text);
    this.seatingPlanDiv
      .selectAll('path.static')
      .data(fixedElements.paths)
      .enter()
      .append('path')
      .attr(
        'transform',
        d =>
          'translate(' +
          (this.seatingPlanService.planWidth * d.x) / 100 +
          ' ' +
          (((this.seatingPlanService.planHeight - this.stageSize) * d.y) / 100 + this.stageSize) +
          ') scale(1)'
      )
      .attr('d', d => d.d)
      .attr('class', 'static');

    if (this.reservationService.isAdmin) {
      seatElement
        .append('text')
        .attr('text-anchor', 'middle')
        .attr('transform', () => {
          const rotateText = false; // rotatePlan

          if (rotateText) {
            return 'translate(' + (this.seatingPlanService.seatWidth + 10) / 2 + ',' + this.seatingPlanService.seatHeight / 2 + ') rotate(270)';
          }

          return 'translate(' + this.seatingPlanService.seatWidth / 2 + ',' + (this.seatingPlanService.seatHeight + 10) / 2 + ')';
        })
        .attr('class', 'seat-label')
        .text(d => {
          const id = SeatLabelService.getRealSeatId(
            this.seatingPlanService.seatingPlan,
            d.r,
            d.c,
            this.reservationService.seatingPlan.labelingOrder,
            this.seatingPlanService.exceptions
          );
          return SeatLabelService.getSeatLabel(this.seatingPlanService.seatingPlan, this.seatingPlanService.exceptions, id);
        });
    }

    for (let index = 0; index < this.reservationService.reservation.selectedSeats.length; ++index) {
      const data = d3.select('#seat-' + this.reservationService.reservation.selectedSeats[index]).data();
      if (data.length > 0) {
        data[0].s = true;
        data[0].t = false;
      }
    }

    // Remove loading text.
    // Remove loading text.
    d3.select('#loading').remove();
  }

  createCircle(root, i: number, max: number) {
    root
      .append('circle')
      .attr('transform', 'translate(' + (12 * (i - 1) - 6 * max) + ' 12)')
      .attr('cx', this.seatingPlanService.planWidth / 2)
      .attr('cy', this.seatingPlanService.planHeight / 2)
      .attr('r', 0)
      .attr('fill', '#666666')
      .append('animate')
      .attr('attributeName', 'r')
      .attr('values', '0; 4; 0; 0')
      .attr('dur', '1.2s')
      .attr('repeatCount', 'indefinite')
      .attr('begin', 0.6 * (i / max))
      .attr('keytimes', '0;0.2;0.7;1')
      .attr('keySplines', '0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8')
      .attr('calcMode', 'spline');
  }

  /**
   * Creates an array of table items.
   * @returns {Array} of items with r (row index) and t (table index)
   */
  tables(): any[] {
    const result = [];

    for (let r = 1; r <= 2 * this.reservationService.seatingPlan.rows; r++) {
      for (let t = 1; t <= this.reservationService.seatingPlan.tablesPerRow; t++) {
        result.push({
          r: r, // row
          t: t, // table in row
          seatIds: this.collectRawSeatIdsOfTable(r, t)
        });
      }
    }

    return result;
  }

  collectRawSeatIdsOfTable(row: number, table: number) {
    const seatIds = [];
    const seatsPerTable = (2 * this.reservationService.seatingPlan.seatsPerRow) / this.reservationService.seatingPlan.tablesPerRow;
    const startSeatId =
      SeatLabelService.getRawSeatId(
        this.seatingPlanService.seatingPlan,
        Math.floor(row * 2) - 1,
        ((table - 1) * this.reservationService.seatingPlan.seatsPerTable) / 2 + 1,
        this.reservationService.seatingPlan.labelingOrder
      ) - (row % 2 === 1 ? 0 : 1);

    for (let seatId = startSeatId; seatId < startSeatId + seatsPerTable; seatId++) {
      seatIds.push(seatId);
    }
    return seatIds;
  }

  fixedElements(): any {
    const result = {
      rects: [],
      texts: [],
      lines: [],
      circles: [],
      paths: []
    };
    const fixedElements: string[] = this.reservationService.seatingPlan.fixedElements.split('\r\n');
    fixedElements.forEach(fixedElement => {
      const definitionArray = fixedElement.split('|');
      switch (definitionArray[0]) {
        case 'rect':
          result.rects.push({
            x: parseFloat(definitionArray[1]),
            y: parseFloat(definitionArray[2]),
            width: parseFloat(definitionArray[3]),
            height: parseFloat(definitionArray[4])
          });
          break;
        case 'text':
          result.texts.push({
            x: parseFloat(definitionArray[1]),
            y: parseFloat(definitionArray[2]),
            text: definitionArray[3],
            size: (definitionArray[4] ? parseFloat(definitionArray[4]) : 12) + 'px'
          });
          break;
        case 'line':
          result.lines.push({
            x1: parseFloat(definitionArray[1]),
            y1: parseFloat(definitionArray[2]),
            x2: parseFloat(definitionArray[3]),
            y2: parseFloat(definitionArray[4])
          });
          break;
        case 'circle':
          result.circles.push({
            cx: parseFloat(definitionArray[1]),
            cy: parseFloat(definitionArray[2]),
            r: parseFloat(definitionArray[3])
          });
          break;
        case 'path':
          result.paths.push({
            x: parseFloat(definitionArray[1]),
            y: parseFloat(definitionArray[2]),
            d: definitionArray[3]
          });
          break;
      }
    });
    return result;
  }

  getSeatLabel(seatId: number) {
    return SeatLabelService.getSeatLabel(this.seatingPlanService.seatingPlan, this.seatingPlanService.exceptions, seatId);
  }

  getSeatingPlan(performance: Performance) {
    if (performance) {
      this.isLoadingSeatingPlan = true;

      this.drawLoadingSeatingPlan();

      const seatingPlanAndCategoriesSubscription = forkJoin([
        this.seatingPlanService.getSeatingPlan(performance),
        this.categoryService.loadCategories(performance)
      ]).subscribe(
        result => {
          if (seatingPlanAndCategoriesSubscription) {
            seatingPlanAndCategoriesSubscription.unsubscribe();
          }
          const [seatingPlan, categories] = result;
          this.reservationService.seatingPlan = seatingPlan;
          this.changeDetectorRef.markForCheck();
          setTimeout(() => {
            this.drawSeatingPlan();
            this.updateSeatingPlan();
            this.isLoadingSeatingPlan = false;
            this.changeDetectorRef.markForCheck();
          }, 0);
        },
        error => {
          window.location.reload();
        }
      );
    }
  }

  ngOnDestroy() {
    if (this.performanceSubscription) {
      this.performanceSubscription.unsubscribe();
    }

    if (this.redrawSeatingPlanSubscription) {
      this.redrawSeatingPlanSubscription.unsubscribe();
    }
  }
}
