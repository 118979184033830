import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {AdminService} from '../../../services/admin.service';
import {MessageService} from '../../../services/message.service';
import {ReservationService} from '../../../services/reservation.service';
import {SeatLabelService} from '../../../services/seat-label.service';
import {SeatingPlanService} from '../../../services/seating-plan.service';

@Component({
  selector: 'app-check',
  templateUrl: './check.component.html',
  styleUrls: ['./check.component.scss'],
  encapsulation: ViewEncapsulation.None // allows styling of elements deeper in the component
})
export class CheckComponent implements OnInit, OnDestroy {

  baseRouteSubscription: Subscription;
  public addressLinkRoute: string[];

  constructor(private router: Router,
              public reservationService: ReservationService,
              public seatingPlanService: SeatingPlanService,
              private adminService: AdminService,
              private messageService: MessageService,
              private seatLabelService: SeatLabelService,
              private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.baseRouteSubscription = this.adminService.routeBaseChanged.subscribe(routeBase => {
      this.addressLinkRoute = routeBase.concat('address');
    });
    this.addressLinkRoute = this.adminService.getRoute('address');

    if (!this.seatingPlanService.seatingPlan) {
      const seatingPlanSubscription = this.seatingPlanService
        .getSeatingPlan(this.reservationService.reservation.performance)
        .subscribe((seatingPlan) => {
          // Subscription is required to automatically refresh the seat labels when the seating plan has been loaded.
          this.changeDetectorRef.markForCheck();
          if (seatingPlanSubscription) {
            seatingPlanSubscription.unsubscribe();
          }
        });
    }

    this.scrollToTop();
  }

  scrollToTop() {
    const pageTitleElement = document.getElementById('pageTitle');
    pageTitleElement.scrollIntoView();
  }

  book() {
    const bookingSubscription = this.reservationService
      .book(this.adminService.getRoute('confirmation'))
      .subscribe((result) => {
          this.changeDetectorRef.markForCheck();
          bookingSubscription.unsubscribe();
        },
        (error) => {
          this.messageService.alert('Fehler',
            'Es ist ein Fehler bei der Reservation aufgetreten. ' +
            'Bitte überprüfen Sie Ihre Angaben noch einmal. ' +
            'Sollte der Fehler weiterhin auftreten, nehmen Sie bitte mit uns Kontakt auf.');
          this.reservationService.isBooking = false;
          this.changeDetectorRef.markForCheck();
          bookingSubscription.unsubscribe();
        });
  }

  navigate(route) {
    this.router.navigate(route);
  }

  getSeatLabel(seatingPlan, seatId) {
    if (seatingPlan) {
      return SeatLabelService.getSeatLabel(seatingPlan, this.seatingPlanService.exceptions, seatId);
    }
  }

  ngOnDestroy() {
    if (this.baseRouteSubscription) {
      this.baseRouteSubscription.unsubscribe();
    }
  }
}
